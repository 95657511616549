<template>
    <b-avatar :size="size" :icon="getUserProfileIcon" :text="getUserAbbreviatedName" :src="getUserProfileImage" />
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: "ProfileAvatar",
    props: {
        size: {
            required: false,
            default: '2rem',
            type: String,
        }
    },
    computed: {
        ...mapGetters({
            getUserProfileIcon: "authentication/userProfileIcon",
            getUserAbbreviatedName: "authentication/userAbbreviatedName",
            getUserProfileImage: "authentication/userProfileImage",
        }),
    },
}
</script>
<style scoped>
</style>