<template>
    <b-container fluid v-if="shouldNotify">
        <b-row class="justify-content-start" align-v="center" align-h="start">
            <b-col>
                <span class="text-danger">
                  <i class="fas fa-exclamation-circle"/>
                  Notify {{ notificationTarget }} Immediately.
                </span>
            </b-col>
        </b-row>
        <b-row class="justify-content-start" align-v="center" align-h="start">
            <b-col cols="1"> &nbsp;</b-col>
            <b-col cols="11">
                <b-row>
                    <b-checkbox
                        v-model="sendEscalation"
                        :disabled="!hasEscalation"
                    />
                    Escalations: {{ interventionSummary }}
                </b-row>
                <b-row>
                    <b-checkbox v-model="sendAssessments" :disabled="!hasChangeInCondition"/>
                    Changes in Condition: {{ assessmentSummary }}
                </b-row>
                <b-row>
                    <b-checkbox v-model="sendVitalSigns" :disabled="!hasVitalSign"/>
                    Vital Signs: {{ vitalSignsSummary }}
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {notificationCriteria} from "@/helpers/vitalSignTools";
import {mapGetters, mapMutations} from "vuex";
import {
    bloodGlucoseVerification,
    bloodPressureVerification, hasIrregularPulse, oxygenSaturationVerification,
    pulseVerification,
    respiratoryRateVerification, temperatureVerification
} from "@/helpers/vitalSignTools";

export default {
    name: "MessageAlertPanel",
    computed: {
        ...mapGetters({
            getSendAssessments: "messages/getSendAssessments",
            getSendVitalSigns: "messages/getSendVitalSigns",
            getSendInterventions: "messages/getSendInterventions",
            episode: "episode/getEpisode",
        }),

        notificationTarget() {
            return this.$store.state.user.role === "DOCTOR" ? "Team" : "Doctor";
        },

        shouldNotify() {
            return this.hasEscalation || this.hasChangeInCondition || this.hasVitalSign;
        },

        hasEscalation() {
            return this.episode?.interventions?.some((intervention) => this.escalationShouldNotify(intervention))
        },

        hasChangeInCondition() {
            return this.episode?.signsAndSymptoms?.some((assessment) => this.changeInConditionShouldNotify(assessment))
        },

        hasVitalSign() {
            return this.episode?.vitals?.some((vitalSign) => notificationCriteria(vitalSign))
        },

        vitalSignsSummary() {
            const vitalSigns = this.episode?.vitals?.filter((vitalSign) => notificationCriteria(vitalSign));
            if (!vitalSigns) {
                return "No Vital Signs";
            }

            if (vitalSigns.length === 0) {
                return "No Vital Signs";
            }

            if (vitalSigns.length > 1) {
                return "Multiple";
            }

            const vitalSign = vitalSigns[0];

            let vitalSignSummary = ``;

            const bloodGlucose = vitalSign.blood_glucose;
            const bloodPressure = vitalSign.blood_pressure;
            const pulse = vitalSign.heart_rate;
            const restingPulse = vitalSign.resting_pulse;
            const respiratoryRate = vitalSign.respiration_rate;
            const temperature = vitalSign.temperature;
            const oxygenSaturation = vitalSign.o2_saturation;
            const irregularPulse = vitalSign.irregular_pulse;

            let hasBloodGlucoseEvent = bloodGlucoseVerification(bloodGlucose)
            let hasBloodPressureEvent = bloodPressureVerification(bloodPressure)
            let hasPulseEvent = pulseVerification(pulse)
            let hasRespiratoryEvent = respiratoryRateVerification(respiratoryRate)
            let hasTemperatureEvent = temperatureVerification(temperature)
            let hasOxygenSaturationEvent = oxygenSaturationVerification(oxygenSaturation)
            let hasRestingPulseEvent = pulseVerification(restingPulse)
            let hasIrregularPulseEvent = hasIrregularPulse(irregularPulse)

            if (hasBloodGlucoseEvent) {
                vitalSignSummary += `Blood Glucose Event. `
            }
            if (hasBloodPressureEvent) {
                vitalSignSummary += `Blood Pressure Event. `
            }
            if (hasPulseEvent) {
                vitalSignSummary += `Pulse Event. `
            }
            if (hasRespiratoryEvent) {
                vitalSignSummary += `Respiratory Rate Event. `
            }
            if (hasTemperatureEvent) {
                vitalSignSummary += `Temperature Event. `
            }
            if (hasOxygenSaturationEvent) {
                vitalSignSummary += `Oxygen Saturation Event. `
            }
            if (hasRestingPulseEvent) {
                vitalSignSummary += `Resting Pulse Event. `
            }
            if (hasIrregularPulseEvent) {
                vitalSignSummary += `Irregular Pulse Event. `
            }

            const occurrencesCaseInsensitive = (vitalSignSummary.match(/\bEvent\b/gi) || []).length;

            if (occurrencesCaseInsensitive > 2) {

                return "Multiple";
            }
            return vitalSignSummary;
        },

        assessmentSummary() {
            const assessments =
                this.episode?.signsAndSymptoms?.filter(
                    (assessment) => this.changeInConditionShouldNotify(assessment)
                );

            if (assessments?.length > 0) {
                if (assessments.length > 1) {
                    return "Multiple";
                }

                return assessments[0].signsAndSymptom?.symptom;
            }

            return "No Assessments";
        },

        interventionSummary() {
            const interventions =
                this.episode?.interventions?.filter(
                    (intervention) => this.escalationShouldNotify(intervention)
                );

            if (interventions?.length > 0) {
                if (interventions.length > 1) {
                    return "Multiple";
                }

                let escalationName = interventions[0].intervention?.intervention;

                if (!escalationName) {
                    escalationName = interventions[0].intervention;
                }

                return escalationName;
            }

            return "No Interventions";
        },

        sendAssessments: {
            get() {
                return this.getSendAssessments;
            },
            set(value) {
                this.setSendAssessments(value);
            },
        },

        sendVitalSigns: {
            get() {
                return this.getSendVitalSigns;
            },
            set(value) {
                this.setSendVitalSigns(value);
            },
        },

        sendEscalation: {
            get() {
                return this.getSendInterventions;
            },
            set(value) {
                this.setSendInterventions(value);
            },
        },
    },

    methods: {
        ...mapMutations({
            setSendAssessments: "messages/setSendAssessments",
            setSendVitalSigns: "messages/setSendVitalSigns",
            setSendInterventions: "messages/setSendInterventions",
        }),

        escalationShouldNotify(escalation) {
            const isActive = escalation.active === 1 || escalation.active === true;
            const hasNotification =
                escalation.notified === 1 || escalation.notified === true;

            return isActive && !hasNotification;
        },

        changeInConditionShouldNotify(condition) {
            const isActive = condition.status === 1 || condition.status === true;
            const hasNotification =
                condition.has_notified === 1 || condition.has_notified === true;
            // const requiresNotification =
            //     condition.requires_notification === 1 ||
            //     condition.requires_notification === true;

            // return isActive && requiresNotification && !hasNotification;
            return isActive && !hasNotification;
        },
    },
};
</script>

<style scoped></style>
