<template>
    <div class="fa fa-warning alert-notifications" @click="filterByAlerts">
        <span class="badge badge-alert">
          {{ alertCount }}
        </span>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: "DashboardAlertButton",
    computed: {
        ...mapGetters({
            alertCount: "episodes/alertCount",
        })
    },
    methods: {
        filterByAlerts() {
            this.$emit('filterByAlerts')
        }
    },
}
</script>

<style scoped>

</style>