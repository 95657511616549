<template>
    <b-container fluid="xl">
        <div v-if="isLoading">
            Loading...
            <b-spinner variant="info"></b-spinner>
        </div>
        <div v-if="!isLoading">
            <form>
                <b-form-row>
                    <app-button @click="showResidentInfo" :isSelected="residentInfo">Resident Information</app-button>
                    <app-button @click="showFamilyInfo" :isSelected="familyInfo">Family / Caregiver / Contact
                    </app-button>
                    <app-button @click="showDirectives" :isSelected="showDirective">Advanced Directives / Goals of
                        Care
                    </app-button>
                </b-form-row>

                <div v-if="residentInfo">
                    <b-form-row class="mt-2">
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-mrn"
                                label="MRN Number: "
                                label-for="basic-info-mrn"
                            >
                                <b-form-input
                                    id="basic-info-mrn"
                                    v-model="mrnNumber"
                                    type="number"
                                    placeholder="Enter the MRN Number"
                                    :readonly="gateMrnField"
                                    :state="mrnNumberIsValid"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-ssn"
                                label="SS Number: "
                                label-for="basic-info-ssn"
                            >
                                <b-form-input
                                    id="basic-info-ssn"
                                    v-model="socialSecurityNumber"
                                    :readonly="isReadOnlyField"
                                    type="text"
                                    placeholder="Enter the social Security Number"
                                    :state="socialSecurityNumberIsValid"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-first-name"
                                label="Resident First Name: "
                                label-for="basic-info-resident-first-name"
                            >
                                <b-input
                                    id="basic-info-resident-first-name"
                                    v-model="firstName"
                                    type="text"
                                    :readonly="isReadOnlyField"
                                    placeholder="Enter the Residents First Name"
                                    :state="firstNameIsValid"
                                ></b-input>
                            </b-form-group>
                        </b-col>

                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-last-name"
                                label="Resident Last Name: "
                                label-for="basic-info-resident-last-name"
                            >
                                <b-input
                                    id="basic-info-resident-last-name"
                                    v-model="lastName"
                                    type="text"
                                    :readonly="isReadOnlyField"
                                    placeholder="Enter the Residents Last Name"
                                    :state="lastNameIsValid"
                                ></b-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-dob"
                                label="Resident Date of Birth: "
                                label-for="basic-info-resident-dob"
                            >
                                <b-form-datepicker
                                    id="basic-info-resident-dob"
                                    :readonly="isReadOnlyField"
                                    v-model="dateOfBirth"
                                    :state="dateOfBirthIsValid"
                                    show-decade-nav
                                ></b-form-datepicker>
                            </b-form-group>
                        </b-col>

                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-blood-group"
                                label="Blood Group: "
                                label-for="basic-info-blood-group"
                            >
                                <b-select
                                    v-model="bloodGroupId"
                                    :options="blood_groups"
                                    :state="bloodGroupIsValid"
                                ></b-select>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-room-number"
                                label="(Optional) Room Number: "
                                label-for="basic-info-resident-room-number"
                            >
                                <b-input
                                    id="basic-info-resident-room-number"
                                    v-model="residentRoom"
                                    type="text"
                                ></b-input>
                            </b-form-group>
                        </b-col>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-gender"
                                label="Gender: "
                                label-for="basic-info-resident-gender"
                            >
                                <b-select
                                    v-model="genderId"
                                    :options="genders"
                                    :readonly="isReadOnlyField"
                                    :state="genderIsValid"
                                ></b-select>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-account-number"
                                label="Account Number: "
                                label-for="basic-info-resident-account-number"
                            >
                                <b-form-input
                                    id="basic-info-resident-account-number"
                                    v-model="accountNumber"
                                    type="text"
                                    :state="accountNumberIsValid"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-admission"
                                label="Resident Date of Admission: "
                                label-for="basic-info-resident-admission"
                            >
                                <b-form-datepicker
                                    id="basic-info-resident-admission"
                                    :readonly="isReadOnlyField"
                                    v-model="admissionDate"
                                    :state="admissionDateIsValid"
                                ></b-form-datepicker>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-language"
                                label="Language: "
                                label-for="basic-info-resident-language"
                            >
                                <b-select
                                    v-model="languageId"
                                    :options="languages"
                                    :readonly="isReadOnlyField"
                                    :state="languageIsValid"
                                ></b-select>
                            </b-form-group>
                            <b-form-group v-if="showOtherLanguage">
                                <b-form-input
                                    v-model="languageOther"
                                    :readonly="isReadOnlyField"
                                    placeholder="Please describe the other language."
                                    :state="languageOtherIsValid"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-ethnicity"
                                label="Race / Ethnicity: "
                                label-for="basic-info-resident-ethnicity"
                            >
                                <b-select
                                    v-model="ethnicityId"
                                    :options="ethnicities"
                                    :readonly="isReadOnlyField"
                                    :state="ethnicityIsValid"
                                ></b-select>
                            </b-form-group>
                            <b-form-group v-if="showOtherRace">
                                <b-form-input
                                    v-model="ethnicityOther"
                                    :readonly="isReadOnlyField"
                                    placeholder="Please describe the other race / ethnicity."
                                    :state="ethnicityOtherIsValid"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-medical-insurance"
                                label="Medical Insurance Plan: "
                                label-for="basic-info-resident-medical-insurance"
                            >
                                <b-select
                                    v-model="medicalInsuranceId"
                                    :options="medical_insurances"
                                    :state="medicalInsuranceIsValid"
                                ></b-select>
                            </b-form-group>
                        </b-col>

                        <b-col class="sm-6">
                            <b-form-group
                                id="basic-info-group-resident-care-types"
                                label="Care Types: "
                                label-for="basic-info-resident-care-types"
                            >
                                <b-select
                                    v-model="careTypeId"
                                    :options="care_types"
                                    :readonly="isReadOnlyField"
                                    :state="careTypeIsValid"
                                ></b-select>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="sm-6"></b-col>
                        <b-col class="sm-6"></b-col>
                    </b-form-row>

                    <b-modal
                        ref="modal-for-basic-info-validation"
                        centered
                        title="Error"
                        ok-only
                        header-text-variant="danger"
                    >
                        <p class="my-4">
                            Please ensure that all resident information except room number is
                            filled in.
                        </p>
                    </b-modal>
                </div>

                <!-- care giver / contact information -->
                <div v-if="familyInfo">
                    <episode-care-giver-form></episode-care-giver-form>

                    <episode-care-giver-table :family="getEpisode.patient.family"
                                              :episodeId="getEpisode.id"></episode-care-giver-table>
                </div>

                <!-- Care Directives -->
                <episode-directives-of-care v-if="showDirective"></episode-directives-of-care>
            </form>
        </div>
    </b-container>
</template>
<script>
// import axios from "axios";
import {constantMixin} from "@/mixins/constants.js"
import {Roles} from "@/mixins/constants.js"
import {mapGetters, mapMutations} from "vuex"
import AppButton from '@/components/AppButton.vue'
import EpisodeCareGiverForm from '@/components/Episode/EpisodeCareGiverForm.vue'
import EpisodeCareGiverTable from '@/components/Episode/EpisodeCareGiverTable.vue'
import EpisodeDirectivesOfCare from '@/components/Episode/EpisodeDirectivesOfCare.vue'

export default {
    components: {
        'app-button': AppButton,
        'episode-care-giver-form': EpisodeCareGiverForm,
        'episode-care-giver-table': EpisodeCareGiverTable,
        'episode-directives-of-care': EpisodeDirectivesOfCare,
    },
    data() {
        return {
            isReadOnlyField: false,
            isInAddMode: false,
            care_providers: [],
            blood_groups: [],
            blood_group_undefined: {
                value: null,
                disabled: true,
                text: "Please Select a Blood Group.",
            },
            directives: [],

            contacts: [],

            genders: [],
            gender_undefined: {
                value: null,
                disabled: true,
                text: "Please Select a Gender",
            },
            ethnicities: [],
            ethnicity_undefined: {
                value: null,
                disabled: true,
                text: "Please Select an Ethnicity",
            },
            languages: [],
            language_undefined: {
                value: null,
                disabled: true,
                text: "Please Select a Language",
            },
            medical_insurances: [],
            medical_insurance_undefined: {
                value: null,
                text: "Please Select an Insurance Plan",
                type: "Not Set",
                disabled: true,
            },
            care_types: [],
            care_type_undefined: {
                value: null,
                disabled: true,
                text: "Please Select a Care Type",
            },
            care_provider_undefined: {
                value: null,
                text: "Please Select a Care Provider.",
                disabled: true,
            },
            isLoading: true,
            residentInfo: true,
            familyInfo: false,
            showDirective: false,
        };
    },
    mounted() {
        this.loadData();
    },
    computed: {
        ...mapGetters({
            getGenders: "configuration/genders",
            getBloodGroups: "configuration/bloodGroups",
            getEthnicities: "configuration/ethnicities",
            getLanguages: "configuration/languages",
            getMedicalInsurancePlans: "configuration/medicalInsurancePlans",
            getCareTypes: "configuration/careTypes",
            getRole: "user/getRole",
            getEpisode: "episode/getEpisode",
            mrnNumberIsValid: "episode/mrnNumberIsValid",
            socialSecurityNumberIsValid: "episode/socialSecurityNumberIsValid",
            dateOfBirthIsValid: 'episode/dateOfBirthIsValid',
            bloodGroupIsValid: 'episode/bloodGroupIsValid',
            genderIsValid: 'episode/genderIsValid',
            accountNumberIsValid: 'episode/accountNumberIsValid',
            admissionDateIsValid: 'episode/admissionDateIsValid',
            languageIsValid: 'episode/languageIsValid',
            languageOtherIsValid: 'episode/languageOtherIsValid',
            ethnicityIsValid: 'episode/ethnicityIsValid',
            ethnicityOtherIsValid: 'episode/ethnicityOtherIsValid',
            medicalInsuranceIsValid: 'episode/medicalInsuranceIsValid',
            careTypeIsValid: 'episode/careTypeIsValid',
            firstNameIsValid: 'episode/firstNameIsValid',
            lastNameIsValid: 'episode/lastNameIsValid',
            getEpisodeFacilityId: 'episode/getEpisodeFacilityId',
        }),
        showOtherRace() {

            //  we should show the Ethnicities Other input box if the selected ethnicity has the value of OTHER
            return !!this.ethnicities.find((ethnicity) => ethnicity.text === "OTHER" && ethnicity.value === this.ethnicityId)
        },
        showOtherLanguage() {

            return !!this.languages.find((language) => language.text === "Other" && language.value === this.languageId)
        },
        firstName: {
            get() {
                return this.getEpisode?.patient?.name;
            },
            set(value) {
                this.updateFirstName(value);
            },
        },
        lastName: {
            get() {
                return this.getEpisode?.patient?.last_name;
            },
            set(value) {
                this.updateLastName(value);
            },
        },
        socialSecurityNumber: {
            get() {
                return this.getEpisode?.patient?.social_security_number;
            },
            set(value) {
                this.updateSocialSecurityNumber(value);
            },
        },
        mrnNumber: {
            get() {
                return this.getEpisode?.patient?.mrn_number;
            },
            set(value) {
                this.updateMrnNumber(value);
            },
        },
        dateOfBirth: {
            get() {
                return this.getEpisode?.patient?.date_of_birth;
            },
            set(value) {
                this.updateDateOfBirth(value);
            },
        },
        bloodGroupId: {
            get() {
                return this.getEpisode?.patient?.blood_group;
            },
            set(value) {
                console.log(value)
                this.updateBloodGroupId(value);
            },
        },
        residentRoom: {
            get() {
                return this.getEpisode?.bed_number;
            },
            set(value) {
                this.updateRoom(value);
            },
        },
        genderId: {
            get() {
                return this.getEpisode?.patient?.gender_id;
            },
            set(value) {
                this.updateGenderId(value);
            },
        },
        accountNumber: {
            get() {
                return this.getEpisode?.account_number;
            },
            set(value) {
                this.updateAccountNumber(value);
            },
        },
        admissionDate: {
            get() {
                return this.getEpisode?.admitted_at;
            },
            set(value) {
                this.updateAdmissionDate(value);
            },
        },
        languageId: {
            get() {
                return this.getEpisode?.patient?.language_id;
            },
            set(value) {
                this.updateLanguageId(value);
            },
        },
        languageOther: {
            get() {
                return this.getEpisode?.patient?.language_other;
            },
            set(value) {
                this.updateLanguageOther(value);
            },
        },
        ethnicityId: {
            get() {
                return this.getEpisode?.patient?.ethnicity_id;
            },
            set(value) {
                this.updateEthnicityId(value);
            },
        },
        ethnicityOther: {
            get() {
                return this.getEpisode?.patient?.ethnicity_other;
            },
            set(value) {
                this.updateEthnicityOther(value);
            },
        },
        medicalInsuranceId: {
            get() {
                return this.getEpisode?.medical_insurance_type;
            },
            set(value) {
                this.updateMedicalInsuranceId(value);
            },
        },
        careTypeId: {
            get() {
                return this.getEpisode?.care_type_id;
            },
            set(value) {
                this.updateCareTypeId(value);
            },
        },
        gateMrnField() {
            //  determine the users role
            //  check if the role is in the allowed roles.

            let allowedRoles = [
                Roles.CertifiedNursingAssistant,
                Roles.Nurse,
                Roles.Doctor,
                Roles.Administrator,
                Roles.RegisteredNurseAdministrator,
                Roles.SuperAdministrator,
            ];

            return allowedRoles.some((role) => {
                return role === this.getRole;
            });
        },
    },
    methods: {
        ...mapMutations({
            updateMrnNumber: "episode/updateMrnNumber",
            updateSocialSecurityNumber: "episode/updateSocialSecurityNumber",
            updateDateOfBirth: "episode/updateDateOfBirth",
            updateBloodGroupId: "episode/updateBloodGroupId",
            updateRoom: "episode/updateRoomNumber",
            updateGenderId: "episode/updateGenderId",
            updateAccountNumber: "episode/updateAccountNumber",
            updateAdmissionDate: "episode/updateDateOfAdmission",
            updateLanguageId: "episode/updateLanguageId",
            updateLanguageOther: "episode/updateLanguageOther",
            updateEthnicityId: "episode/updateEthnicityId",
            updateEthnicityOther: "episode/updateEthnicityOther",
            updateMedicalInsuranceId: "episode/updateMedicalInsurancePlan",
            updateCareTypeId: "episode/updateCareType",
            updateFirstName: "episode/updateFirstName",
            updateLastName: "episode/updateLastName",
            updateFamilyMember: "episode/updateFamilyMember",
            setEpisodeFacilityId: "episode/setEpisodeFacilityId",
        }),

        hideAll() {
            this.residentInfo = false;
            this.familyInfo = false;
            this.showDirective = false;
            //  console.log(this.$parent.episode.patient);
        },
        showResidentInfo() {
            this.hideAll();
            this.residentInfo = true;
        },
        showFamilyInfo() {
            this.hideAll();
            if (this.$parent.episodeId === 0) {
                alert("Please save the resident before adding Guardians.");
                return;
            }
            this.familyInfo = true;
        },
        showDirectives() {
            this.hideAll();
            this.showDirective = true;
        },
        processDirectives() {
            if (this.$parent.episodeId !== 0) {
                if (this.$parent.episode.directive) {
                    this.$parent.episode.directive.full_code
                        ? this.directives.push("full_code")
                        : null;
                    this.$parent.episode.directive.dnr
                        ? this.directives.push("dnr")
                        : null;
                    this.$parent.episode.directive.dni
                        ? this.directives.push("dni")
                        : null;
                    this.$parent.episode.directive.dnh
                        ? this.directives.push("dnh")
                        : null;
                    this.$parent.episode.directive.comfort_care
                        ? this.directives.push("comfort_care")
                        : null;
                    this.$parent.episode.directive.hospice_care
                        ? this.directives.push("hospice_care")
                        : null;
                    this.$parent.episode.directive.other
                        ? this.directives.push("other")
                        : null;
                    this.$parent.episode.directive.goal_of_care_discussed
                        ? this.directives.push("goal_of_care_discussed")
                        : null;
                    this.$parent.episode.directive.decision_making_capacity
                        ? this.directives.push("decision_making_capacity")
                        : null;
                }
            }
        },
        determineIfNotReadOnly() {
            this.isReadOnlyField = !(this.$parent.mode !== constantMixin.residentEditMode ||
                (this.$store.getters["authentication/user"].roles.find(
                        (u) =>
                            u.role.name === constantMixin.SUPERADMIN &&
                            u.facility_id === this.$parent.episode.facility_id
                    ) !== undefined &&
                    this.$parent.mode === constantMixin.residentEditMode));
        },
        hideBtnOnEditMode() {
            //Hides the Family/Care Giver/Contact btn when in add mode
            if (this.$parent.mode === constantMixin.residentAddMode) {
                this.isInAddMode = true;
                this.setFacilityId()
            } else {
                this.isInAddMode = false;
            }
        },
        async loadData() {
            this.isLoading = true;
            this.determineIfNotReadOnly();
            this.hideBtnOnEditMode();
            //  await this.loadConfigurationData();
            await this.loadGenders();
            await this.loadLanguages();
            await this.loadEthnicities();
            await this.loadMedicalInsurancePlans();
            await this.loadBloodGroups();
            await this.loadCareTypes();
            //  await this.loadCareProviders();
            //  this.raceChange();
            //  this.languageChange();
            this.processDirectives();
            this.isLoading = false;
        },
        async loadCareTypes() {
            this.care_types = [];
            this.care_types = this.getCareTypes;
        },
        async loadBloodGroups() {
            this.blood_groups = [];
            this.blood_groups = this.getBloodGroups;
        },
        async loadMedicalInsurancePlans() {
            this.medical_insurances = [];
            this.medical_insurances = this.getMedicalInsurancePlans;

            if (!this.$parent.episode.medical_insurance_type) {
                this.$parent.episode.medical_insurance_type = null;
            }
        },
        async loadLanguages() {
            this.languages = [];
            this.languages = this.getLanguages;
        },
        async loadEthnicities() {
            this.ethnicities = [];
            this.ethnicities = this.getEthnicities;
        },
        async loadGenders() {
            this.genders = [];
            this.genders = this.getGenders;
        },
        setFacilityId() {
            if (this.getEpisodeFacilityId === null) {

                this.setEpisodeFacilityId(this.$store.state.episodes.facility.id)
            }
        },
    },
};
</script>
