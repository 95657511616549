<template>
    <div class="dashboard">
        <nav class="nav-bar">
            <AptusCareLogo/>

            <div
                class="search-container searchbar-medium searchbar-small searchbar-width-small"
            >
                <button>
                    <i class="fa fa-search"></i>
                </button>
                <input
                    type="text"
                    v-model="search_text"
                    @input="searchResidentRoom()"
                    class="search-bar searchbar-text-medium searchbar-text-small"
                    placeholder="Search Resident or Room"
                />
                <button style="float: right" @click="clearSearch()">
                    <i class="fa fa-remove"></i>
                </button>
            </div>

            <UserMenu @signOut="signOut" />

            <!-- Alert and Message Icons -->
            <dashboard-alert-button @filterByAlerts="filterByAlerts" />
            <!-- End Alerts and Message Icons -->

            <!-- Facility Selection List -->
            <button v-popover:facilityname.bottom class="facility-name">
                {{ currentFacility.name }}
                <i class="fa fa-chevron-down"></i>
            </button>

            <!-- Add New Facility Resident -->
            <button
                v-if="$root.logged_user.auth_to_add"
                class="add-new-resident"
                @click="addNewResident()"
            >
                <i class="fa fa-plus"></i>Add New Resident
            </button>
        </nav>

        <div class="app-body">
            <div class="filter-sort-container filter-sort-container-padding">
                <div class="filter-container">
                    <span style="white-space: nowrap">FILTER BY:</span>

                    <label
                        class="container filter-sort-container-text"
                        id="allResidentsLabel"
                    >
                        All Residents
                        <input
                            type="checkbox"
                            name="filter"
                            v-model="isAllResidents"
                            :checked="isAllResidents"
                            @click="showAllResidents()"
                        />
                        <span class="checkmark">
              <i class="fa fa-filter"></i>
            </span>
                    </label>

                    <label
                        class="container filter-sort-container-text"
                        id="conditionChangedLabel"
                    >
                        Escalation
                        <input
                            type="checkbox"
                            name="filter"
                            v-model="isConditionChanged"
                            :checked="isConditionChanged"
                            @click="showResidentsWithInterventions()"
                        />
                        <span class="checkmark">
              <i class="fa fa-filter"></i>
            </span>
                    </label>
                </div>
                <div class="sort-container">
                    <span style="white-space: nowrap">SORT BY:</span>
                    <div
                        class="container filter-sort-container-text"
                        id="action_priority_label"
                        @click="handleSortByAction('action_priority')"
                    >
                        Action Priority
                        <span class="checkmark-sort">
              <i class="fa"></i>
            </span>
                    </div>
                    <div
                        class="container filter-sort-container-text"
                        id="admission_date_label"
                        @click="handleSortByAction('admission_date')"
                    >
                        Admission Date
                        <span class="checkmark-sort">
              <i class="fa"></i>
            </span>
                    </div>

                    <div
                        class="container filter-sort-container-text"
                        id="name_label"
                        @click="handleSortByAction('name')"
                    >
                        Resident Name
                        <span class="checkmark-sort">
              <i class="fa"></i>
            </span>
                    </div>

                    <div
                        class="container filter-sort-container-text"
                        id="room_label"
                        @click="handleSortByAction('room')"
                    >
                        Room Number
                        <span class="checkmark-sort">
              <i class="fa"></i>
            </span>
                    </div>
                </div>
            </div>

            <!-- primary Episodes Tables -->
            <b-overlay :show="episodeIsLoading" rounded="sm">
                <b-container fluid class="mt-1 pt-1">

                    <div class="app-content">
                        <div class="app-content-container">
                            <div
                                style="height: 30%; width: 100%; background-color: #c5c3d1"
                                v-if="sortedEpisodes.length === 0"
                            >
                                <p class="no-result-found">No Results found...</p>
                            </div>
                            <episode-list-item :episodes="sortedEpisodes" @showLoader="showLoader"
                                               @hideLoader="hideLoader"/>
                        </div>
                    </div>

                </b-container>
            </b-overlay>
        </div>

        <popover name="facilityname" class="popover-scrollable">
            <ul class="facility-name-popover">
                <li
                    @click="getFilterFacilityEpisodes(facility)"
                    v-for="facility in facilities"
                    :key="facility.id"
                >
                    {{ facility.name }}
                </li>
            </ul>
        </popover>
        <div class="app-footer">
            Version {{ version }} Copyright &#169; {{ current_year }} AptusCare. All Rights Reserved.
        </div>

        <b-modal
            id="facilityManager"
            title="Facility Manager"
            size="huge"
            centered
            scrollable
            content-class="shadow"
            no-close-on-backdrop
            header-bg-variant="dark"
            header-text-variant="light"
            hide-footer
        >
            <facility-manager></facility-manager>
        </b-modal>

        <b-modal
            id="facilityStaffManager"
            title="Staff Manager"
            size="xl"
            centered
            scrollable
            content-class="shadow"
            no-close-on-backdrop
            header-bg-variant="dark"
            header-text-variant="light"
            hide-footer
        >
            <user-manager :facilityId="facilityId"></user-manager>
        </b-modal>

        <b-modal
            id="residentModal"
            title="Resident Information"
            size="huge"
            centered
            scrollable
            content-class="shadow"
            no-close-on-backdrop
            no-close-on-esc
            no-enforce-focus
            hide-header
            hide-footer
        >
            <resident-editor
                :mode="residentModalMode"
                :page="page"
                :refresh="refresh"
            ></resident-editor>
        </b-modal>

        <b-modal
            id="pointClickCareOnboarding"
            title="PointClickCare Onboarding"
            size="xl"
            centered
            show-backdrop
            content-class="shadow"
            no-close-on-backdrop
            no-close-on-esc
            show-header
            header-bg-variant="dark"
            header-text-variant="light"
            hide-footer
        >
            <point-click-care-onboarding-facility></point-click-care-onboarding-facility>
        </b-modal>

        <b-modal
            id="pointClickCareDischarging"
            title="PointClickCare Discharging"
            size="xl"
            centered
            show-backdrop
            content-class="shadow"
            no-close-on-backdrop
            no-close-on-esc
            show-header
            header-bg-variant="dark"
            header-text-variant="light"
            hide-footer
        >
            <point-click-care-discharging-facility></point-click-care-discharging-facility>
        </b-modal>

        <b-modal
            id="newPasswordModal"
            title="Change Password"
            size="x"
            centered
            show-backdrop
            content-class="shadow"
            no-close-on-backdrop
            no-close-on-esc
            show-header
            hide-footer
        >
            <b-form @submit.prevent>
                <div
                    id="msg-result"
                    style="display: none"
                    class="success-msg"
                    :class="{ 'error-msg': !password_changed }"
                >
                    {{ result_msg }}
                </div>
                <b-form-row>
                    <b-form-group
                        id="user-old-password-group"
                        label="Current Password"
                        label-for="user-old-password"
                    >
                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                            <template v-slot:prepend>
                                <b-input-group-text>
                                    <font-awesome-icon icon="user-secret"></font-awesome-icon>
                                </b-input-group-text>
                            </template>
                            <b-form-input
                                id="user-old-password"
                                v-model="new_password_form.current_password"
                                type="password"
                                required
                                placeholder="Enter Current Password"
                                size="lg"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <b-form-group
                        id="user-new-password-group"
                        label="New Password"
                        label-for="user-new-password"
                    >
                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                            <template v-slot:prepend>
                                <b-input-group-text>
                                    <font-awesome-icon icon="user-secret"></font-awesome-icon>
                                </b-input-group-text>
                            </template>
                            <b-form-input
                                id="user-new-password"
                                v-model="new_password_form.new_password"
                                type="password"
                                required
                                placeholder="Enter New Password"
                                size="lg"
                                @input="checkPasswordMatch()"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <b-form-group
                        id="user-new-password-group"
                        label="Confirm New Password"
                        label-for="user-confirm-new-password"
                    >
                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                            <template v-slot:prepend>
                                <b-input-group-text>
                                    <font-awesome-icon icon="user-secret"></font-awesome-icon>
                                </b-input-group-text>
                            </template>
                            <b-form-input
                                id="user-confirm-new-password"
                                v-model="new_password_form.confirmed_new_password"
                                type="password"
                                required
                                placeholder="Confirm New Password"
                                size="lg"
                                @input="checkPasswordMatch()"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <label
                        v-if="!password_match && new_password_form.new_password"
                        style="color: red"
                    >Password doesn't match.</label
                    >
                    <label
                        v-if="password_match && new_password_form.confirmed_new_password"
                        style="color: green"
                    >Password matches.</label
                    >
                </b-form-row>
                <b-form-row>
                    <b-button style="margin-right: 73px" @click="changeUserPassword()"
                    >Change Password
                    </b-button>
                    <b-button @click="changePasswordHide()">Close</b-button>
                </b-form-row>
            </b-form>
        </b-modal>

        <messaging-panel></messaging-panel>
        <user-activity-monitor
            @unsubscribe-channels.once="unsubscribe"
        ></user-activity-monitor>
    </div>
</template>

<script>
import "@/assets/css/dashboard-style.css";
import "@/assets/css/fontawesome-free-5.0.13/web-fonts-with-css/css/fontawesome-all.min.css";
import "@/assets/css/material-icons/iconfont/material-icons.css";
import "@/assets/css/font-awesome/font-awesome.css";
import ResidentEditor from "./Episode/ResidentEditor.vue";
import EpisodeListItem from "@/components/Dashboard/EpisodeListItem";
import FacilityManager from "@/components/FacilityManager";
import UserManager from "@/components/users/UserManager.vue";
import PointClickCareOnboardingFacility from "@/components/PointClickCare/PointClickCareOnboarding";
import PointClickCareDischargingFacility from "@/components/PointClickCare/PointClickCareDischarging";
import MessagingPanel from "@/components/Messaging/Panel.vue";
import UserActivityMonitor from "@/components/Dashboard/UserActivityMonitor";
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";
import {pageHandler} from "@/mixins/constants";

import * as helpers from "@/helpers/helpers";
import DashboardAlertButton from "@/components/Dashboard/DashboardAlertButton.vue";
import AptusCareLogo from "@/components/AptusCareLogo.vue";
import UserMenu from "@/components/Dashboard/UserMenu.vue";

export default {
    name: "Dashboard",
    title: "Dashboard",
    props: {
        test_msg: String,
    },
    components: {
        UserMenu,
        AptusCareLogo,
        DashboardAlertButton,
        UserActivityMonitor,
        EpisodeListItem,
        ResidentEditor,
        MessagingPanel,
        FacilityManager,
        UserManager,
        PointClickCareOnboardingFacility,
        PointClickCareDischargingFacility,
    },
    data: function () {
        return {
            search_text: "",
            isAllResidents: true,
            isConditionChanged: false,
            facilities: [],
            selected_facility_id: null,
            selected_facility_name: "",
            secondary_episodes: [],
            current_year: new Date().getFullYear(),
            currentUser: {
                id: null,
                name: "",
                user_role: "",
                can_add: false,
                role_name: "",
                role_description: "",
                role_type: "",
            },
            user2: {},
            residentModalMode: "add",
            episode: 0,
            page: "basicInfo",
            new_password_form: {
                current_password: null,
                new_password: null,
                confirmed_new_password: null,
            },
            password_changed: true,
            result_msg: "Message",
            password_match: false,
            displayState: "ALL_RESIDENTS",
            sortBy: "roomNumberLabel",
            sortDirection: "ASC",
            selectedEpisode: null,
            messagingPanelVisible: false,
            sessionTimeoutIntervalId: null,
            pusher: null,
            sortedEpisodes: [],
            channel: null,
            episodeIsLoading: false,
            version: process.env.VUE_APP_VERSION,
        };
    },

    mounted() {
        this.firstLoad();
        this.$root.$on("refreshFacilityBroadcast", () => {
        });
        this.setUpChannels();
    },

    created() {
        if (!this.$root.logged_user.auth_to_add) {
            this.user_menu_attribute = "";
            this.user_menu_attribute_val = "";
        }
    },
    computed: {
        ...mapGetters({
            authenticated: "authentication/authenticated",
            user: "authentication/user",
            role: "user/getRole",
            alertCount: "episodes/alertCount",
            getEpisodes: "episodes/getEpisodes",
            getEpisodesWithInterventions: "episodes/getEpisodesWithInterventions",
            getCurrentFacility: "user/getCurrentFacility",
            getRoles: "user/getRoles",
            facility: "episodes/getFacility",
            currentFacility: "user/getCurrentFacility",
            isAdministrator: "user/isAdministrator",
            isSiteAdministrator: "user/isSiteAdministrator",
            episodes: "episodes/episodes",
            interventions: "configuration/interventions",
        }),



        orderBy: {
            set(orderBy) {
                this.$store.dispatch("episodes/updateOrderBy", orderBy);
            },

            get() {
                return this.$store.getters["episodes/getOrderBy"];
            },
        },

        facilityId() {
            return this.currentFacility.id ? this.currentFacility.id : 0;
        },
    },
    watch: {
        search_text: function () {
            this.searchResidentRoom();
        },
    },
    methods: {
        ...mapActions({
            logoutAction: "authentication/logout",
            loadConfigurationData: "configuration/loadConfigurationData",
            getCareProvidersByFacility: "configuration/getCareProvidersByFacility",
            setEpisodeToHighPriority: "episodes/setEpisodePriorityToHigh",
            getEpisodeByFacility: "episodes/getEpisodeByFacility",
            setEpisodeFacility: "episodes/setFacility",
            setCurrentFacility: "episodes/setCurrentFacility",
            sortEpisodes: "episodes/sortEpisodes",
            loadUser: "user/loadUser",
            setFacility: "user/setFacility",
            updateFacility: "user/updateFacility",
            setRoleByFacility: "user/setRoleByFacility",
            unloadEpisode: "episode/unloadEpisode",
            loadEpisodeById: "episode/loadEpisodeById",
            closeMessages: "messages/closeMessages",
            fetchEpisode: "episode/fetchEpisode",
            loadMessages: "messages/loadMessages",
            createNewEpisode: "episode/addNewResident",
            updateFacilityEpisodes: "episodes/updateFacilityEpisodes",
            showAllResidentsFromStore: "episodes/showAllResidents",
            showResidentsWithInterventionsFromStore: "episodes/showAllInterventions",
            unloadEpisodes: "episodes/unload",
            unloadImmunizations: "immunizations/unload",
            unloadMessages: "messages/unload",
            unloadUser: "user/unload",
            updateEpisodeFromEvent: "episodes/updateEpisodeFromEvent",
        }),
        ...mapMutations({
            setFacilityId: "episodes/setFacilityId",
            setFacilityName: "episodes/setFacilityName",
            setUserCurrentFacility: "user/setCurrentFacility",
            setEpisodeId: "episode/setEpisodeId",
            updateEpisodeUnreadMessageState: "episodes/updateEpisodeUnreadMessageState",
        }),

        showLoader() {
            this.episodeIsLoading = true
        },

        hideLoader() {
            this.episodeIsLoading = false
        },

        resortEpisodes(by) {
            if (by === this.sortBy) {
                if (this.sortDirection.toUpperCase() === "ASC") {
                    this.sortDirection = "DESC";
                } else {
                    this.sortDirection = "ASC";
                }
            }

            if (by !== this.sortBy) {
                this.sortDirection = "ASC";
                this.sortBy = by;
            }

            switch (this.sortBy) {
                case "action_priority":
                    this.sortEpisodesByActionPriority();
                    break;
                case "name":
                    this.sortEpisodesByPatientName();
                    break;
                case "room":
                    this.sortEpisodesByRoomNumber();
                    break;
                case "admission_date":
                    this.sortEpisodesByAdmissionDate();
                    break;
            }
        },

        sortEpisodesByActionPriority() {
            this.sortedEpisodes = this.episodes.sort(
                function (a, b) {
                    if (this.sortDirection === "ASC") {
                        return parseInt(a.priority) < parseInt(b.priority) ? 1 : -1;
                    }

                    if (this.sortDirection === "DESC") {
                        return parseInt(a.priority) > parseInt(b.priority) ? 1 : -1;
                    }
                }.bind(this)
            );
        },
        sortEpisodesByAdmissionDate() {
            this.episodes.sort(
                function (a, b) {
                    if (this.sortDirection === "ASC") {
                        return a.admissionDate === b.admissionDate
                            ? 0
                            : a.admissionDate > b.admissionDate
                                ? 1
                                : -1;
                    }

                    if (this.sortDirection === "DESC") {
                        return a.admissionDate === b.admissionDate
                            ? 0
                            : a.admissionDate < b.admissionDate
                                ? 1
                                : -1;
                    }
                }.bind(this)
            );
        },

        sortEpisodesByPatientName() {
            this.episodes.sort(
                function (a, b) {
                    if (this.sortDirection === "ASC") {
                        return a.patientFullName === b.patientFullName
                            ? 0
                            : a.patientFullName > b.patientFullName
                                ? 1
                                : -1;
                    }

                    if (this.sortDirection === "DESC") {
                        return a.patientFullName === b.patientFullName
                            ? 0
                            : a.patientFullName < b.patientFullName
                                ? 1
                                : -1;
                    }
                }.bind(this)
            );
        },

        sortEpisodesByRoomNumber() {
            this.episodes.sort(
                function (a, b) {
                    if (this.sortDirection === "ASC") {
                        return a.roomNumber === b.roomNumber
                            ? 0
                            : a.roomNumber > b.roomNumber
                                ? 1
                                : -1;
                    }

                    if (this.sortDirection === "DESC") {
                        return a.roomNumber === b.roomNumber
                            ? 0
                            : a.roomNumber < b.roomNumber
                                ? 1
                                : -1;
                    }
                }.bind(this)
            );
        },

        setUpChannels() {
            const Pusher = require("pusher-js");

            Pusher.logToConsole = true;
            this.pusher = new Pusher(process.env.VUE_APP_WEB_SOCKET_KEY, {
                cluster: process.env.VUE_APP_WEB_SOCKET_CLUSTER,
            });
        },

        subscribe() {
            const Pusher = require("pusher-js");
            if (!(this.pusher instanceof Pusher)) {
                console.error(`Pusher is not initialized.`);
                try {
                    this.setUpChannels()
                } catch (error) {

                    console.error(`UNABLE TO START PUSHER ${error.message}`)
                    return
                }
            }
            this.channel = this.pusher.subscribe(
                `facility.${this.currentFacility.id}`
            );

            this.channel.bind("facility.episode.update.event", (data) => {
                this.updateEpisodeUpdateNotification(data)
            })

            this.channel.bind("facility.message_event", (data) => {
               this.updateEpisodeUnreadMessageState(data.episode)
            })
        },

        async updateEpisodeUpdateNotification(data) {
            await this.updateEpisodeFromEvent(data)
            this.sortedEpisodes = this.episodes;
            if(data.shouldNotify === true) {
                this.triggerNotification(data.user.name, data.episode.patientFullName);
            }
        },

        triggerNotification(username, patientName) {
            if(this.authenticated) {
                this.$bvToast.toast(`${username} updated patient ${patientName}`, {
                    title: `${patientName} Updated...`,
                    toaster: "b-toaster-bottom-right",
                    solid: true,
                    appendToast: true,
                    variant: "info",
                });
            }
        },

        unsubscribe() {
            if (this.channel) {
                try {
                    this.channel.unbind("facility.event");
                } catch (error) {

                    console.error(`PUSHER ERROR: ${error.message()}`);
                }
            }

            this.pusher = null;
        },

        showAllResidents() {
            this.showAllResidentsFromStore();
            this.displayState = "ALL_RESIDENTS";
            this.isAllResidents = true;
            this.isConditionChanged = false;
            this.underlineElementById("allResidentsLabel");
            this.removeUnderlineElementById("conditionChangedLabel");
            this.sortedEpisodes = this.episodes;
            this.handleSortByAction();
        },

        showResidentsWithInterventions() {
            this.showResidentsWithInterventionsFromStore();
            this.displayState = "INTERVENTIONS";
            this.isAllResidents = false;
            this.isConditionChanged = true;
            this.underlineElementById("conditionChangedLabel");
            this.removeUnderlineElementById("allResidentsLabel");
            this.sortedEpisodes = this.episodes;
            this.handleSortByAction();
        },

        async firstLoad() {
            if (this.authenticated) {
                // this.setSessionTimeoutIntervalId();
                await this.loadUser(this.user);
                await this.loadConfigurationData();
                await this.setEpisodeFacility(this.currentFacility.id);
                await this.subscribe();
                await this.loadPage();
            }
        },

        underlineElementById(elementId) {
            document.getElementById(elementId).style.textDecoration = "underline";
        },
        removeUnderlineElementById(elementId) {
            document.getElementById(elementId).style.textDecoration = "unset";
        },

        refresh() {
            this.loadPage();
        },
        async loadPage() {
            this.$bvModal.show("loaderModal");
            await this.getEpisodeByFacility();
            await this.processFacilities(this.getRoles);
            this.sortedEpisodes = this.episodes;
            this.$bvModal.hide("loaderModal");
        },

        processFacilities(facilities) {
            facilities.forEach((facility) => {
                if (!this.hasFacilityInArray(facility.facility)) {
                    let item = {
                        id: facility.facility.id,
                        name: facility.facility.name,
                    };

                    this.facilities.push(item);
                }
            });
        },

        hasFacilityInArray(facility) {
            return this.facilities.find((item) => item.id === facility.id);
        },

        async getFilterFacilityEpisodes(facility) {
            this.$bvModal.show("loaderModal");
            this.unsubscribe();
            this.setFacilityId(facility.id);
            this.setFacilityName(facility.name);
            let facilityItem = {
                id: facility.id,
                name: facility.name,
            };
            await this.setCurrentFacility(facilityItem);
            this.setUserCurrentFacility(facilityItem);
            await this.getCareProvidersByFacility(facility.id)

            await this.getEpisodeByFacility();
            await this.setRoleByFacility(facility.id);
            this.sortedEpisodes = this.episodes;
            this.handleSortByAction();
            this.subscribe();
            this.$bvModal.hide("loaderModal");
        },

        filterByAlerts() {
            this.showResidentsWithInterventions();
        },

        isElement: function (o) {
            return typeof HTMLElement === "object"
                ? o instanceof HTMLElement //DOM2
                : o &&
                typeof o === "object" &&
                true &&
                o.nodeType === 1 &&
                typeof o.nodeName === "string";
        },

        searchResidentRoom: function () {
            let filter, table, tr, td, i;
            filter = this.search_text.toUpperCase();
            table = document.getElementById("resident-items");
            tr = table.getElementsByTagName("tr");
            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                td = tr[i].getElementsByTagName("td")[0];
                if (td) {
                    if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        },
        clearSearch: function () {
            this.search_text = "";
        },


        addNewResident: function () {
            this.unloadEpisode();
            this.residentModalMode = "add";
            this.episode = helpers.generateId();
            this.page = "basicInfo";
            pageHandler.page = screen;
            pageHandler.residentModalMode = this.residentModalMode;
            this.createNewEpisode();
            this.$bvModal.show("residentModal");
        },
        removeCheckMarkFromSortFilters: function () {
            const listOfFilters = document.querySelectorAll(
                "div.filter-sort-container-text"
            );
            listOfFilters.forEach((item) => {
                if (this.isElement(item)) {
                    item.style.textDecoration = "unset";
                    item
                        .querySelectorAll("span.checkmark-sort")[0]
                        .children[0].classList.remove("fa-arrow-up");
                    item
                        .querySelectorAll("span.checkmark-sort")[0]
                        .children[0].classList.remove("fa-arrow-down");
                }
            });
        },

        handleSortByAction: function (filter_type) {
            this.removeCheckMarkFromSortFilters();
            this.resortEpisodes(filter_type);
            this.applyCheckMarkAndUnderline(filter_type);
        },

        applyCheckMarkAndUnderline: function (divId) {
            let divIdLabel = divId + "_label";

            let selectedElement = document.getElementById(divIdLabel);

            if (selectedElement) {
                selectedElement.style.textDecoration = "underline";

                let checkBoxElement = selectedElement.children[0]?.children[0];

                if (this.sortDirection === "ASC") {
                    checkBoxElement.className = "fas fa-arrow-up";
                } else {
                    checkBoxElement.className = "fas fa-arrow-down";
                }
            }
        },

        showEditor: function (episode, mode, screen) {
            this.setEpisodeId(episode.id);
            this.$root.selected_resident = episode;
            this.episode = episode.id;
            this.residentModalMode = mode;
            this.page = screen;
            pageHandler.page = screen;
            pageHandler.residentModalMode = this.residentModalMode;
            this.$bvModal.show("residentModal");
        },


        changePassword() {
            this.$bvModal.show("newPasswordModal");
        },
        changePasswordHide() {
            this.$bvModal.hide("newPasswordModal");
            this.new_password_form.current_password = null;
            this.new_password_form.new_password = null;
            this.new_password_form.confirmed_new_password = null;
        },
        async changeUserPassword() {
            document.getElementById("msg-result").style.display = "none";
            const self = this;
            if (this.password_match) {
                this.$bvModal.show("loaderModal");
                let response = await axios.post(
                    "changePassword",
                    this.new_password_form
                );
                if (
                    response.status === 200 &&
                    response.data.message === "password changed."
                ) {
                    this.password_changed = true;
                    this.result_msg = "Password changed.";
                    document.getElementById("msg-result").style.display = "block";
                    setTimeout(function () {
                        self.changePasswordHide();
                    }, 5000);
                } else if (
                    response.status === 200 &&
                    response.data.message === "wrong current password"
                ) {
                    this.password_changed = false;
                    this.result_msg = "Wrong current password provided.";
                    document.getElementById("msg-result").style.display = "block";
                } else {
                    //  output errors.
                    this.password_changed = false;
                    this.result_msg = "Password was not successfully changed.";
                    document.getElementById("msg-result").style.display = "block";
                }
                this.$bvModal.hide("loaderModal");
            }
        },
        checkPasswordMatch() {
            this.password_match =
                this.new_password_form.new_password ===
                this.new_password_form.confirmed_new_password;
        },

        async signOut() {
            this.$bvModal.show("loaderModal");
            await this.deregister();
            await this.logoutAction();
            this.$bvModal.hide("loaderModal");
            await this.$router.push({name: "Login"});
        },


        async deregister() {
            this.unsubscribe();
            await this.unloadEpisodes();
            await this.unloadImmunizations();
            await this.unloadMessages();
            await this.unloadEpisode();
            await this.unloadUser();
        },
    },
};
</script>

<style>
label.filter-sort-container-text {
    margin-bottom: 0;
}

.filter-container {
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    padding: 6px;
    border-radius: 20px;
    margin-left: 8px;
}

.sort-container {
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    padding: 6px;
    border-radius: 20px;
    min-width: 58%;
}

.sort-container div {
    flex: 0 0 12em;
}

.popover-scrollable {
    height: 400px;
    overflow-y: auto;
    white-space: pre-wrap;
}

#newPasswordModal .form-row {
    margin-left: 70px;
    margin-right: 70px;
}

.success-msg {
    background-color: aquamarine;
    color: #000;
    text-align: center;
}

.error-msg {
    background-color: crimson;
    color: #fff;
    text-align: center;
}

.icon-margin.icon-margin {
    height: 36px;
}

.resident-row {
    display: flex;
    height: 96px;
}

.resident-row td {
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

/*----------Below is CSS for TABLET type screen-sizes----------*/
@media screen and (max-width: 1440px) {
    .filter-sort-container {
        flex-direction: column;
    }

    .filter-container {
        width: fit-content;
    }

    .sort-container {
        width: fit-content;
        min-width: 75%;
    }
}

@media screen and (max-width: 1400px) {
    .spacing-for-tablet-th-12 {
        width: 12%;
    }

    .spacing-for-tablet-th-20 {
        width: 23%;
    }

    .spacing-for-tablet-th-15 {
        width: 15%;
    }

    .edit-font-size {
        font-size: 100%;
        text-align: left;
    }

    .icon-margin {
        margin: auto;
    }

    .status-msg-width-height {
        width: 23%;
    }
}

@media screen and (max-width: 1023px) {
    .small-tablet-width-font {
        font-size: 70%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
    .med-tablet-width-font {
        font-size: 85%;
    }

    .med-tablet-width-font-status {
        font-size: 90%;
    }

    .edit-font-size-med {
        font-size: 13px;
        text-align: left;
    }

    .edit-icon-size.edit-icon-size {
        height: 28px;
    }
}

@media screen and (max-width: 1024px) {

    .searchbar-medium {
        width: 24%;
    }

    .searchbar-text-medium {
        font-size: 83%;
        width: 70%;
    }
}

@media screen and (max-width: 970px) {

    .img-small {
        width: 99% !important;
    }

    .searchbar-small {
        width: 24%;
    }

    .searchbar-text-small {
        font-size: 89%;
        width: 70%;
    }

    .searchbar-width-small {
        width: 35%;
    }

    .filter-sort-container-padding {
        padding-top: 5%;
    }

    .filter-sort-container-text {
        font-size: 79% !important;
    }

    .edit-font-size-small {
        font-size: 10px;
        text-align: left;
    }

    .edit-icon-size.edit-icon-size {
        height: 28px;
    }
}
</style>
