<template>
    <b-icon icon="upload" :class="isUrgentClass" variant="light" font-scale="2"></b-icon>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "EscalateIcon",
    props: {
        changeInCondition: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            activeSignsAndSymptoms: 'episode/getSignsAndSymptoms',
            changeInConditions: 'configuration/signsAndSymptoms'
        }),
        isUrgentClass() {
            return {
                'bg-success': this.hasRecommendation,
                'bg-primary': !this.hasRecommendation,
                'rounded-circle': true,
                'p-2': true,
                'm-2': true
            }
        },
        hasRecommendation() {
            const linkedEscalation = this.currentChangeInConditionConfig?.linked_intervention

            return !!linkedEscalation;


        },
        currentChangeInCondition() {
            return this.activeSignsAndSymptoms.find(sign => sign.signs_and_symptom_id === this.changeInCondition.signs_and_symptom_id)
        },
        currentChangeInConditionConfig() {
            return this.changeInConditions.find(sign => sign.id === this.currentChangeInCondition.signs_and_symptom_id)
        },
    }
}
</script>

<style scoped>

</style>