<template>
    <div id="careTeam">
        <div class="container-lg">
            <div class="row residentForm-headding-button-row">
                <app-button :disabled="!canEdit" @click="addCareTeamMember()">Add Care Team Member
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                </app-button>
            </div>

            <div>
            <hr class="bg-primary" />
            </div>

            <div v-if="getCareTeam.length === 0">
                <div class="col-lg-12">
                    <h3>There are no records for this Patient.</h3>
                </div>
            </div>

            <b-table
                striped
                head-variant="dark"
                :items="getCareTeam"
                class="residentTable-style"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                v-if="getCareTeam.length"
            >
                <template v-if="isInAddMode" v-slot:top-row v-bind:data="data">
                    <td class="residentTable-add-td" :span="data.columns">
                        <b-input
                            v-model="iFullNameAdd"
                            class="residentTable-add-input"
                            placeholder="(Name & Surname)"
                            type="text"
                        />
                    </td>
                    <td class="residentTable-add-td" :span="data.columns">
                        <b-input
                            v-model="iSpecialityAdd"
                            class="residentTable-add-input"
                            placeholder="(Designation/Speciality)"
                            type="text"
                        />
                    </td>
                    <td class="residentTable-add-td" :span="data.columns">
                        <b-input
                            v-model="iEmailAdd"
                            class="residentTable-add-input"
                            placeholder="(Email Address)"
                            type="email"
                        />
                    </td>
                    <td class="residentTable-add-td" :span="data.columns">
                        <b-input
                            v-model="iNumberAdd"
                            class="residentTable-add-input"
                            placeholder="(Contact Number)"
                            type="text"
                        />
                    </td>
                    <td :span="data.columns">
                        <b-btn class="residentTable-save-button" @click="addRecord()">
                            SAVE
                        </b-btn>
                    </td>
                    <td class="residentTable-remove-td" :span="data.columns">
                        <b-link @click="removeAddRecord()">
                            <font-awesome-icon
                                class="residentTable-remove-link"
                                :icon="['fas', 'times']"
                            />
                        </b-link>
                    </td>
                </template>
                <template
                    v-for="field in editableFields"
                    v-slot:[`cell(${field.key})`]="{ value, item }"
                >
                    <b-input
                        v-if="selectedRow && selectedRow.id === item.id && isEditShow"
                        :key="field.id"
                        v-model="selectedRow[field.key]"
                        :type="field.type || 'text'"
                        :number="field.isNumber"
                    >
                    </b-input>
                    <template v-else>{{ value }}</template>
                </template>
<!--                <template v-slot:cell(actions)="{ item }">-->
<!--                    <b-button-group-->
<!--                        v-if="selectedRow && selectedRow.id === item.id && isEditShow"-->
<!--                    >-->
<!--                        <b-btn class="residentTable-save-button" @click="saveEdit">-->
<!--                            SAVE-->
<!--                        </b-btn>-->
<!--                    </b-button-group>-->
<!--                    <b-btn-->
<!--                        :disabled="!canEdit"-->
<!--                        v-else-->
<!--                        class="residentTable-edit-button"-->
<!--                        @click="editRecord(item)"-->
<!--                    >-->
<!--                        EDIT-->
<!--                    </b-btn>-->
<!--                </template>-->
                <template v-slot:cell(remove)="{ item }">
                    <app-button :disabled="!canEdit" @click="showRemoveModal(item)" isNarrow isDanger><i class="fas fa-trash"></i></app-button>
                     
                </template>

                <template #cell(is_staff)="data">
                    <div v-if="data.item.facility_staff_id > 0">Yes</div>
                    <div v-else>No</div>
                </template>
            </b-table>

            <div class="">

                <app-note>
                    AptusCare Users receive notifications for their patients.
                </app-note>
            </div>

            <b-modal id="confirmRemoveModal" centered hide-footer hide-header>
                <div class="d-block text-center">
                    <h4 class="primaryTypeFace">
                        Are you sure you want to remove this care team record?
                    </h4>
                </div>
                <div class="d-block text-center">
                    <b-button
                        class="mt-3 residentTable-model-delete-btn"
                        @click="removeListItem()"
                    >DELETE
                    </b-button
                    >
                    <b-button
                        class="mt-3 residentTable-model-cancel-btn"
                        block
                        @click="hideModal"
                    >CANCEL
                    </b-button
                    >
                </div>
            </b-modal>

            <b-modal
                id="addCareTeamMemberModal"
                centered
                title="Add Care Team Member"
                size="lg"
                scrollable
                content-class="shadow"
                no-close-on-backdrop
                header-bg-variant="dark"
                header-text-variant="light"
                hide-footer
            >
                <episode-care-team-manager></episode-care-team-manager>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {constantMixin, Roles} from "@/mixins/constants.js";
import {validationMixin} from "@/mixins/validations.js";
import AppButton from '@/components/AppButton'
import EpisodeCareTeamManager from '@/components/Episode/EpisodeCareTeamManager'
import {mapGetters, mapActions} from 'vuex'
import AppNote from "../AppNote.vue";

export default {
    name: "ResidentCareTeam",
    components: {
        AppNote,
        EpisodeCareTeamManager,
        AppButton,
    },
    computed: {
        ...mapGetters({
            role: 'user/getRole',
            getEpisode: 'episode/getEpisode',
            getCareTeam: 'episode/getCareTeam',
        }),

        careTeam() {

            return this.getEpisode.care_teams
        },
        editableFields() {
            return this.fields.filter((field) => field.editable);
        },
        canEdit() {
            return (
                this.role === Roles.Administrator ||
                Roles.SuperAdministrator ||
                Roles.RegisteredNurseAdministrator
            );
        },
    },
    data() {
        return {
            selectedRow: null,
            meNotAuthorised: false,
            isEditShow: false,
            sortBy: "name",
            sortDesc: true,
            fields: [
                {key: "id", thClass: "d-none", tdClass: "d-none"},
                {key: "facility_staff_id", thClass: "d-none", tdClass: "d-none"},
                {
                    key: "is_staff",
                    label: "AptusCare User",
                    editable: false,
                    sortDirection: "desc",
                    sortable: true,
                },
                {
                    key: "name",
                    label: "Name",
                    editable: true,
                    sortDirection: "desc",
                    sortable: true,
                    thStyle: "width:18%;",
                },
                {
                    key: "speciality",
                    label: "Speciality",
                    editable: true,
                    sortable: true,
                },
                {key: "email", label: "Email", editable: true},
                {
                    key: "contact_number",
                    label: "Number",
                    editable: true,
                    thStyle: "width:15%;",
                },

                {
                    key: "remove",
                    label: "Remove",
                    thClass: "residentTable-remove-th",
                    tdClass: "residentTable-remove-td",
                },
            ],
            activeTab: 1,
            isInAddMode: false,
            iFullNameAdd: "",
            iSpecialityAdd: "",
            iEmailAdd: "",
            iNumberAdd: "",
            itemToRemove: {},
        };
    },
    methods: {
        ...mapActions({
            removeCareTeamMember: 'episode/removeCareTeamMember',
            updateCareTeamMember: 'episode/updateCareTeamMember',
        }),
        addCareTeamMember() {
            //  display a modal with two tabs: Add AptusCare Provider, Add Outside Provider
            this.$bvModal.show("addCareTeamMemberModal");
        },
        loadData() {
            if (this.$parent.mode === constantMixin.residentEditMode) {
                this.determineIfAuthorizedToEditAdd();
            }
        },
        determineIfAuthorizedToEditAdd() {
            this.meNotAuthorised = this.$store.getters["authentication/user"].roles.find(
                (u) =>
                    u.role.name ===
                    (Roles.SuperAdministrator || Roles.RegisteredNurseAdministrator || Roles.Administrator || Roles.RegisteredNurseAdministrator || Roles.Nurse) &&
                    u.facility_id === this.$parent.episode.facility_id
            ) === undefined;
        },

        editRecord(paRecord) {

            this.isEditShow = true;
            this.selectedRow = {...paRecord};
        },
        removeListItem() {

            this.removeCareTeamMember(this.selectedRow)
            this.$bvModal.hide('confirmRemoveModal')
        },
        async saveEdit() {
            if (this.validateEditInputFields() === true) {
                //  update the record
                this.updateCareTeamMember(this.selectedRow)
                this.resetEdit()
            }
        },
        validateEditInputFields() {
            //  let's find out which validation is failing.
            if (!this.selectedRow.name !== "") {
                console.log("Failed Name Validation");
            }

            if (!this.selectedRow.name.length >= 5) {
                console.log("Failed Name Length Validation");
            }

            if (!this.selectedRow.speciality !== "") {
                console.log("Failed Speciality Validation");
            }

            if (!this.selectedRow.email !== "") {
                console.log("Failed Email Validation");
            }

            if (!this.selectedRow.contact_number !== "") {
                console.log("Failed Contact Number Validation");
            }

            if (!this.selectedRow.contact_number.length >= 5) {
                console.log("Failed Contact Number Length Validation");
            }

            if (!validationMixin.methods.validateEmail(this.selectedRow.mail)) {
                console.log("Failed Email is Email Validation");
            }

            if (
                this.selectedRow.name !== "" &&
                this.selectedRow.name.length >= 5 &&
                this.selectedRow.speciality !== "" &&
                this.selectedRow.email !== "" &&
                this.selectedRow.contact_number !== "" &&
                this.selectedRow.contact_number.length >= 5 &&
                validationMixin.methods.validateEmail(this.selectedRow.email) === true
            ) {
                return true;
            } else {
                return false;
            }
        },
        validateAddInputFields() {
            return this.iFullNameAdd !== "" &&
                this.iFullNameAdd.length >= 5 &&
                this.iNumberAdd !== "" &&
                this.iNumberAdd.length >= 5 &&
                this.iEmailAdd !== "" &&
                this.iSpecialityAdd !== "" &&
                validationMixin.methods.validateEmail(this.iEmailAdd) === true;
        },
        resetEdit() {
            this.selectedRow = null;
            this.isEditShow = false;
        },

        showRemoveModal(item) {

            this.isEditShow = false;
            this.selectedRow = item;
            this.$bvModal.show("confirmRemoveModal");
        },

        hideModal() {
            this.selectedRow = null;
            this.$bvModal.hide("confirmRemoveModal");
        },

        async addRecord() {
            if (this.validateAddInputFields() === true) {
                let loCareProvider = {
                    name: this.iFullNameAdd,
                    speciality: this.iSpecialityAdd,
                    email: this.iEmailAdd,
                    contact_number: this.iNumberAdd,
                    is_primary: false,
                    patient_id: this.$parent.episode.patient.id,
                    episode_id: this.$parent.episode.id,
                };

                if (this.$parent.mode === constantMixin.residentEditMode) {
                    console.warn("Post to Care Providers.")
                    await axios
                        .post("/care-providers/", loCareProvider)
                        .then((response) => {

                            this.$parent.original_episode.care_teams.push(
                                response.data.care_provider
                            );
                            this.$parent.episode.care_teams.push(response.data.care_provider);

                            this.setFromAddToView();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } else {
                    this.$parent.episode.care_teams.push(loCareProvider);
                    this.setFromAddToView();
                }
            }
        },
        setFromAddToView() {
            this.viewTableState(1);
            this.clearAddedValues();
        },
        removeAddRecord() {
            this.viewTableState(1);
            this.clearAddedValues();
        },

        clearAddedValues() {
            this.iFullNameAdd = "";
            this.iSpecialityAdd = "";
            this.iEmailAdd = "";
            this.iNumberAdd = "";
        },

        addToTableState(paNumberState) {
            if ((this, this.isInAddMode === false)) {
                this.isInAddMode = true;
                this.activeTab = paNumberState;
            }
        },
        viewTableState(paNumberState) {
            this.isInAddMode = false;
            this.activeTab = paNumberState;
        },

        messageCareTeamOnClick(paNumberState) {
            this.isInAddMode = false;
            this.activeTab = paNumberState;
        },
    },
    mounted() {
        this.loadData();
    },
};
</script>
