

<template>
    <b-row class="justify-content-start" align-v="center" align-h="start" v-if="contentText">
        <b-col cols="1"> &nbsp;</b-col>
        <b-col cols="11">
            <strong>{{ labelText }}</strong> {{ contentText }}
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "ChangeInConditionDetailRow",
    props: {
        labelText: String,
        contentText: String
    }
}
</script>