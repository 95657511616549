<template>
    <div class="logo-container logo-medium logo-small">
        <a style="float: left">
            <img
                width="168"
                class="img-medium"
                src="../assets/Icons/aptuscare-dash-logo.png"
                alt="AptusCare Dashboard Logo"
            />
        </a>
    </div>
</template>
<script>
export default {
    name: 'AptusCareLogo'
}
</script>
<style>

.sort-container div {
    flex: 0 0 12em;
}

.resident-row td {
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

/*----------Below is CSS for TABLET type screen-sizes----------*/
@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1400px) {

}

@media screen and (max-width: 1023px) {
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {

}

@media screen and (max-width: 1024px) {
    .logo-medium {
        width: 12%;
    }

    .img-medium {
        width: 99% !important;
    }

}

@media screen and (max-width: 970px) {
    .logo-small {
        width: 12%;
    }

}
</style>