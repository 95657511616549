export default {
    namespaced: true,
    state: {
        symptoms: [],
        isHighPriority: false,
    },
    getters: {
        hasHighPriority(state) {

            return state.isHighPriority;
        }
    },
    mutations: {
        setHighPriority(state, value) {

            state.isHighPriority = value;
        }
    },
    actions: {},
}