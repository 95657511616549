import axios from "axios";
import {notificationCriteria} from "@/helpers/vitalSignTools";

export default {
    dispatchMarkConversationAsRead(state, episodeId) {
        axios.put(`conversations/${episodeId}/read`)
    },
    unload({commit}) {
        commit("unloadMessages");
        commit("closeMessagePanel");
        commit("setLoadingState", false);
        commit("setMessageEpisodeId", null);
    },

    async getNextSetOfMessages({commit}, id) {

        const response = await axios.get(`messages/get-next-messages/${id}`)

        if (response.status) {

            if (response.status === 200) {

                commit("ADD_MESSAGES", response.data)
            }
        }
    },

    async getChangeInConditionDetail({commit}, assessmentId) {
        commit("CLEAR_CHANGE_IN_CONDITION_DETAIL")
        if (assessmentId) {

            await axios.get(`assessments/${assessmentId}`)
                .then((response) => {
                    commit("SET_CHANGE_IN_CONDITION_DETAIL", response.data.data)
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        commit("SET_CHANGE_IN_CONDITION_DETAIL", null);
                    }

                    if (error.response && error.response.status === 403) {
                        commit("SET_CHANGE_IN_CONDITION_DETAIL", null);
                    }
                })
        }
    },

    addMessageToMessages({commit}, value) {
        commit("pushMessage", value.message);
    },
    async sendNewMessage({state, rootState}, messageText) {
        let messageObject = {
            message: messageText,
            priority: 0,
        };

        //  check to see if any interventions need to be added.
        if (rootState.messages.sendInterventions === true) {
            messageObject.interventions =
                rootState.episode?.episode?.interventions?.map(function (intervention) {
                    if (
                        (intervention.notified === 0 || intervention.notified === false) &&
                        (intervention.active === 1 || intervention.active === true)
                    ) {
                        return intervention.id; // the id doesn't exist until the episode has been reloaded.
                    }
                });
        }
        //  check to see if any vital signs need to be added.
        if (rootState.messages.sendVitalSigns === true) {
            messageObject.vitalSigns = rootState.episode?.episode?.vitals?.map(
                function (vitalSign) {
                    if (notificationCriteria(vitalSign) && (vitalSign.notified === 0 || vitalSign.notified === false)) {
                        return vitalSign.id; // the id doesn't exist until the episode has been reloaded.
                    }
                }
            ).filter(item => {
                return item !== null && item !== undefined && item !== ''
            });
            console.warn("messageObject.vitalSigns: ", messageObject.vitalSigns)
        }
        //  check to see if any assessments need to be added.
        if (rootState.messages.sendAssessments === true) {
            messageObject.assessments =
                rootState.episode?.episode?.signsAndSymptoms?.map(function (
                    assessment
                ) {
                    if (
                        assessment.has_notified === 0 &&
                        // assessment.requires_notification &&
                        assessment.status === 1
                    ) {
                        return assessment.id; // the id doesn't exist until the episode has been reloaded.
                    }
                });
        }

        const response = await axios.post(
            `/messages/${state.episodeId}`,
            messageObject
        );

        if (response.status) {
            return response.status === 200;

        }

        if (response.error) {
            console.error(response.error);
            return false;
        }

        return false;
    },

    async getNextPageOfMessages({state, commit}) {

        if (state.episodeId === null) {

            return
        }

        if (state.page === state.last_page) {

            return
        }

        const page = state.page + 1

        const messages = await axios.get(`episodes/${state.episodeId}/messages/paginated?page=${page}`)

        if (!messages.status || messages.status !== 200) {
            return
        }

        commit("ADD_MESSAGES_TO_MESSAGES", messages.data.data)
        commit("SET_CURRENT_PAGE", messages.data.meta.current_page)
        commit("SET_LAST_PAGE", messages.data.meta.last_page)
    },

    async loadMessages({state, commit}) {
        commit("setLoadingState", true);

        if (state.episodeId === null) {
            commit("setLoadingState", false);
            return;
        }

        const messages = await axios.get(`episodes/${state.episodeId}/messages/paginated`);

        if (messages.status) {
            if (messages.status === 200) {
                commit("setMessages", messages.data.data);
                commit("setLoadingState", false);
                commit("SET_CURRENT_PAGE", messages.data.meta.current_page);
                commit("SET_LAST_PAGE", messages.data.meta.last_page)
                return;
            }

            commit("setLoadingState", false);
            return;
        }

        if (messages.error) {
            console.error(
                `An error occured while fetching the messages: ${messages.error.status} - ${messages.error.response.data}`
            );
            commit("setLoadingState", false);
        }
    },
    subscribeToMessages() {
    },
    formatMessage() {
    },
    closeMessages({commit}) {
        commit("closeMessagePanel");
    },
    openMessages({commit}) {
        commit("openMessagePanel");
    },
    setMessageEpisodeId({commit, dispatch}, episodeId) {
        commit("unloadMessages");
        commit("setMessageEpidoseId", episodeId);
        dispatch("loadMessages");
    },
};
