<template>
    <b-container>
        <b-row class="mb-2">
            <b-col>
                <app-button
                    @click="addNewStaffMember"
                    is-small
                    :is-selected="showAddNewStaffMember"
                >Add New Staff Member
                </app-button>
                <app-button
                    @click="displayStaffMembers"
                    is-small
                    :is-selected="showExistingStaff"
                >Show Staff Members
                </app-button>
            </b-col>
        </b-row>
        <b-row v-if="showExistingStaff">
            <b-col>
                <b-row>
                    <b-col>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </b-col>
                    <b-col>
                        <b-form-group
                            label="Filter By"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="filterInput"
                            class="mb-2"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    v-model="filter"
                                    type="search"
                                    id="filterInput"
                                    placeholder="Type to Search"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''"
                                    >Clear
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-table
                            id="facilityStaffTable"
                            :fields="facilityStaffFields"
                            :items="facilityStaffItems"
                            :filter="filter"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :busy="tableLoading"
                            @filtered="onFiltered"
                        >
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                            <template #cell(actions)="row">
                                <app-button
                                    is-small
                                    is-narrow
                                    is-warning
                                    @click="editUser(row.item)"
                                ><i class="fa fa-edit"></i
                                ></app-button>
                                <app-button
                                    is-small
                                    is-narrow
                                    is-danger
                                    @click="deleteUser(row.item)"
                                ><i class="fa fa-trash-o"></i
                                ></app-button>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="showAddNewStaffMember">
            <b-col>
                <b-form>
                    <b-form-row>
                        <b-col>
                            <b-form-group
                                id="staff-user-email-group"
                                label="Login Email"
                                label-for="staff-user-email"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-user-email"
                                    v-model="staff.user.email"
                                    type="email"
                                    placeholder="Login Email"
                                    :state="hasValidLoginEmail"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            {{ userFeedback }}
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group
                                id="staff-user-email-confirm-group"
                                label="Confirm Login Email"
                                label-for="staff-user-email-confirm"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-user-email-confirm"
                                    v-model="staff.user.email_confirm"
                                    type="email"
                                    placeholder="Confirm Login Email"
                                    :disabled="emailConfirmationState"
                                    :state="hasValidLoginEmailConfirmation"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                                id="staff-user-name-group"
                                label="Name"
                                label-for="staff-user-name"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-user-name"
                                    v-model="staff.user.name"
                                    placeholder="Name and Family Name"
                                    :disabled="nameFieldState"
                                    :state="hasValidName"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group
                                id="staff-user-password-group"
                                label="Password"
                                label-for="staff-user-password"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-user-password"
                                    v-model="staff.user.password"
                                    type="password"
                                    :disabled="passwordStates"
                                    :state="hasValidPassword"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                                id="staff-user-password-confirm-group"
                                label="Confirm Password"
                                label-for="staff-user-confirm-password"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-user-confirm-password"
                                    v-model="staff.user.confirm_password"
                                    type="password"
                                    :disabled="passwordStates"
                                    :state="hasValidPasswordConfirmation"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group
                                id="staff-employee-number-group"
                                label="Employee Number"
                                label-for="staff-employee-number"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-employee-number"
                                    v-model="staff.employee_number"
                                    placeholder="Employee Number"
                                    :disabled="disableUserForm"
                                    :state="hasValidEmployeeNumber"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                                id="staff-started-at-group"
                                label="Start Date"
                                label-for="staff-started-at"
                                label-cols-md="5"
                            >
                                <b-form-datepicker
                                    id="staff-started-at"
                                    v-model="staff.started_at"
                                    :disabled="disableUserForm"
                                    :state="hasValidEmployeeStartDate"
                                ></b-form-datepicker>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group
                                id="staff-role-group"
                                label="Staff Role"
                                label-for="staff-role"
                                label-cols-md="5"
                            >
                                <b-form-select
                                    id="staff-role"
                                    :options="roles"
                                    v-model="staff.role_id"
                                    :disabled="disableUserForm"
                                    :state="hasValidStaffRole"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                                id="staff-ended-at-group"
                                label="End Date"
                                label-for="staff-ended-at"
                                label-cols-md="5"
                            >
                                <b-form-datepicker
                                    id="staff-ended-at"
                                    v-model="staff.ended_at"
                                    :disabled="disableUserForm"
                                ></b-form-datepicker>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group
                                id="staff-speciality-group"
                                label="Speciality"
                                label-for="speciality"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="speciality"
                                    v-model="staff.speciality"
                                    placeholder="Speciality (Optional)"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col></b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group
                                id="staff-work-email-group"
                                label="Facility Email"
                                label-for="staff-work-email"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-work-email"
                                    v-model="staff.work_email"
                                    type="email"
                                    placeholder="Email Address"
                                    :disabled="disableUserForm"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                                id="staff-work-number-group"
                                label="Facility Phone Number"
                                label-for="staff-work-number"
                                label-cols-md="5"
                            >
                                <b-form-input
                                    id="staff-work-number"
                                    v-model="staff.work_number"
                                    placeholder="Telephone Number"
                                    :disabled="disableUserForm"
                                    :state="hasValidFacilityPhoneNumber"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-button
                                variant="primary"
                                @click="saveStaffMember"
                                :disabled="!isReadyToSave"
                            >{{ saveButtonText }}
                            </b-button>
                            <b-button
                                variant="success"
                                @click="reinstateEmployee"
                                v-if="priorEmployee"
                                class="ml-2"
                            >Reinstate
                            </b-button>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-col>
        </b-row>

        <b-modal
            id="userEditor"
            centered
            size="xl"
            header-bg-variant="dark"
            header-text-variant="light"
            hide-footer
            title="User Editor"
            no-close-on-backdrop
        >
            <user-editor :user="user" :fetchUsersByFacility="fetchUsersByFacility"/>
        </b-modal>
    </b-container>
</template>
<script>
import axios from "axios";
import _debounce from "lodash/debounce";
import UserEditor from "./UserEditor.vue";
import AppButton from "../AppButton.vue";
import tab from "bootstrap/js/src/tab";
import {mapActions} from "vuex";

export default {
    props: {
        facilityId: Number,
    },
    components: {
        AppButton,
        UserEditor,
    },
    created: function () {
        this.debounceSearchEmailAddressAlreadyExists = _debounce(
            this.searchForEmailAddress,
            500
        );
    },
    data: function () {
        return {
            facilityStaffItems: [],
            facilityStaffFields: [
                {key: "id", label: "Staff Id"},
                {key: "facility.name", label: "Facility"},
                {key: "name", label: "Name"},
                {key: "employee_number", label: "Employee Number"},
                {key: "work_email", label: "Work Email"},
                {key: "work_number", label: "Work Number"},
                {key: "speciality", label: "Speciality"},
                {key: "role", label: "Role"},
                {key: "actions", label: "Actions"},
            ],
            tableLoading: true,
            showExistingStaff: true,
            showAddNewStaffMember: false,
            staff: {
                user: {
                    name: "",
                    email: "",
                    email_confirm: "",
                    password: "",
                    confirm_password: "",
                },
                facility_id: this.facilityId,
                staff_id: 0,
                employee_number: "",
                started_at: null,
                ended_at: null,
                speciality: "",
                role_id: null,
                work_email: "",
                work_number: "",
            },
            roles: [{value: null, text: "Please select a role."}],
            saveButtonText: "Save",
            saveButtonState: false,
            filter: null,
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            existingUser: {
                accountFound: false,
                error: false,
                hasSearched: false,
                errorMessage: "",
            },
            userMessage: "",
            feedback: "",
            disableUserForm: false,
            hasExistingUserButNotFacility: false,
            passwordStates: false,
            emailConfirmationState: false,
            nameFieldState: false,
            addMode: "new",
            user: {
                name: "John Doe",
                email: "jdoe@sample.org",
            },
            postData: {},
        };
    },
    mounted: function () {
        this.fetchUsersByFacility();
        this.fetchRoles();
    },
    computed: {
        tab() {
            return tab;
        },
        priorEmployee() {
            return (
                this.staff.ended_at != null &&
                this.feedback.existsInThisFacility === true
            );
        },
        userFeedback() {
            if (this.existingUser.message != null) {
                return this.existingUser.message;
            }

            if (this.existingUser.accountFound) {
                this.loadExistingUserData();
                if (this.feedback.existsInThisFacility) {
                    this.disableUserAddForm();
                    if (this.staff.ended_at != null) {
                        return "This user used to work here, would you like to re-instate them?";
                    }

                    return "This use already exists in this facility.";
                } else {
                    return "This user already exists, would you like to add them to this facility?";
                }
            }

            return "";
        },
        emailChanged() {
            return this.staff.user.email;
        },
        hasValidLoginEmail() {
            return this.isEmailAddress(this.staff.user.email);
        },

        hasValidLoginEmailConfirmation() {
            if (this.isExistingUser) {

                return true
            }

            return (
                this.staff.user.email_confirm != null &&
                this.staff.user.email === this.staff.user.email_confirm
            );
        },

        hasValidFacilityPhoneNumber() {
            const validationRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

            return validationRegEx.test(this.staff.work_number);
        },

        hasValidName() {
            return this.staff.user.name != null && this.staff.user.name.length >= 5;
        },

        hasValidPassword() {
            if (this.isExistingUser) {

                return true
            }

            return (
                this.staff.user.password != null && this.staff.user.password.length >= 6
            );
        },

        hasValidPasswordConfirmation() {
            if (this.isExistingUser) {

                return true
            }

            return (
                this.staff.user.confirm_password != null &&
                this.staff.user.password === this.staff.user.confirm_password
            );
        },

        isExistingUser() {
            return this.existingUser.accountFound ?? false
        },

        hasValidPasswordCondition() {

            if (this.isExistingUser) {

                return true
            }

            return this.hasValidPassword && this.hasValidPasswordConfirmation;
        },

        hasValidEmployeeNumber() {
            return this.staff.employee_number.length >= 5;
        },

        hasValidEmployeeStartDate() {
            return this.staff.started_at != null;
        },
        hasValidStaffRole() {
            return this.staff.role_id != null;
        },
        isReadyToSave() {
            return (
                this.hasValidLoginEmail &&
                this.hasValidLoginEmailConfirmation &&
                this.hasValidName &&
                this.hasValidPasswordCondition &&
                this.hasValidEmployeeNumber &&
                this.hasValidEmployeeStartDate &&
                this.hasValidStaffRole &&
                this.hasValidFacilityPhoneNumber
            );
        },
    },
    watch: {
        emailChanged: function () {
            this.existingUser = {
                message: "Waiting for an email address...",
            };

            if (this.isEmailAddress(this.emailChanged)) {
                this.existingUser = {
                    message: "Searching...",
                };
                this.debounceSearchEmailAddressAlreadyExists();
            }
        },
        existingUser: function () {
            if (this.existingUser.message) {
                this.userMessage = this.existingUser.message;
            }

            if (this.existingUser.accountFound) {
                this.userMessage = this.existingUser.name;
            } else {
                this.userMessage = "Email Address Not Registered";
            }
        },
    },
    methods: {
        ...mapActions({
            getCareProvidersByFacility: "configuration/getCareProvidersByFacility",
        }),
        showStaffMembers() {
            this.showStaffMembers = true;
            this.showAddNewStaffMember = false;
        },

        showAddStaffMembers() {
            this.showStaffMembers = false;
            this.showAddNewStaffMember = true;
        },

        async reinstateEmployee() {
            const confirmation = await this.$bvModal
                .msgBoxConfirm("Are you sure you want to re-instate this user?", {
                    centered: true,
                })
                .then((response) => {
                    return response;
                });

            if (confirmation) {
                await this.processUserEnabled(this.staff.staff_id);
            }
        },
        editUser(item) {
            this.user = item;
            this.$bvModal.show("userEditor");
        },
        async deleteUser(item) {
            const confirmation = await this.$bvModal
                .msgBoxConfirm("Are you sure you want to delete this user?", {
                    centered: true,
                })
                .then((response) => {
                    return response;
                });

            if (confirmation) {
                await this.processUserDisable(item.id);
            }
        },
        async processUserDisable(id) {
            await axios
                .delete(`/facility-staff/${id}`)
                .then(async () => {
                    await this.$bvModal.msgBoxOk("User Deleted.", {centered: true});
                })
                .catch(async (error) => {
                    console.log(error.message);
                    await this.$bvModal.msgBoxOk(
                        `An error occurred deleting the user: ${error.message}`,
                        {centered: true}
                    );
                })
                .finally(async () => await this.fetchUsersByFacility());
        },

        async processUserEnabled(id) {
            await axios
                .put(`/facility-staff/${id}/facility/${this.facilityId}`)
                .then(async () => {
                    await this.$bvModal.msgBoxOk("User Enabled.", {centered: true});
                    await this.fetchUsersByFacility();
                    this.resetStaffObject();
                })
                .catch(async (error) => {
                    console.log(error.message);
                    await this.$bvModal.msgBoxOk(
                        `An error occurred enabling the user: ${error.message}`,
                        {centered: true}
                    );
                })
                .finally(async () => await this.fetchUsersByFacility());
        },
        loadExistingUserData() {
            this.staff = {
                user: {
                    name: "" || this.existingUser.name,
                    email: "" || this.existingUser.email,
                    email_confirm: "" || this.existingUser.email,
                    password: null,
                    confirm_password: null,
                    id: this.existingUser.id,
                },
                facility_id: this.facilityId,
                staff_id: null,
                employee_number: "",
                started_at: null,
                ended_at: null,
                speciality: "",
                role_id: null,
                work_email: "",
                work_number: "",
            };

            if (this.existingUser.facility) {
                this.staff.facility_id = this.existingUser.facility.id;
                this.staff.staff_id = this.existingUser.facility.staff_id;
                this.staff.employee_number =
                    "" || this.existingUser.facility.employee_number;
                this.staff.started_at = this.existingUser.facility.started_at;
                this.staff.ended_at = this.existingUser.facility.ended_at;
                this.staff.speciality = "" || this.existingUser.facility.speciality;
                this.staff.role_id = this.existingUser.facility.role_id;
                this.staff.work_email = "" || this.existingUser.facility.work_email;
                this.staff.work_number = "" || this.existingUser.facility.work_number;
            } else {
                this.disablePartialInputs();
                this.staff.facility_id = this.facilityId;
                this.staff.staff_id = null;
                this.staff.employee_number = "";
                this.staff.started_at = null;
                this.staff.ended_at = null;
                this.staff.speciality = "";
                this.staff.role_id = null;
                this.staff.work_email = "";
                this.staff.work_number = "";
            }
        },
        disablePartialInputs() {
            this.disableUserForm = false;
            this.addMode = "existing";
            this.disablePasswordFields();
            this.disableEmailConfirmation();
            this.disableNameField();
        },
        disablePasswordFields() {
            this.passwordStates = true;
        },
        disableEmailConfirmation() {
            this.emailConfirmationState = true;
        },
        disableNameField() {
            this.nameFieldState = true;
        },
        clearExistingUserData() {
            this.staff = {
                user: {
                    name: "",
                    email: "",
                    email_confirm: "",
                    password: "",
                    confirm_password: "",
                },
                facility_id: null,
                staff_id: null,
                employee_number: "",
                started_at: null,
                ended_at: null,
                speciality: "",
                role_id: null,
                work_email: "",
                work_number: "",
            };
        },
        enableUserAddForm() {
            this.disableUserForm = false;
        },
        disableUserAddForm() {
            this.disablePasswordFields();
            this.disableEmailConfirmation();
            this.disableUserForm = true;
            this.saveButtonState = true;
        },
        async searchForEmailAddress() {
            const email = this.emailChanged;
            const response = await axios
                .post("/user-search/email", {
                    email: email,
                    facility_id: this.facilityId,
                })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    this.existingUser = {
                        message: "Could not connect to the search API.",
                        error: true,
                        errorMessage: error.message,
                    };

                    return {
                        accountFound: false,
                        error: true,
                    };
                });

            if (response.accountFound) {
                this.existingUser = response.user;
                this.existingUser.accountFound = true;
                this.existingUser.facility = response.facility;
            } else {
                if (!response.error) {
                    this.existingUser = {
                        message: "Unused Email Address",
                        accountFound: false,
                        error: false,
                    };
                }
            }
            this.processExistingUser();
        },
        processExistingUser() {
            if (this.existingUser.accountFound) {
                let existsInThisFacility = false;
                let facility = 0;

                if (this.existingUser.facility != null) {
                    existsInThisFacility =
                        this.existingUser.facility.facility_id === this.facilityId;
                    facility = this.existingUser.facility.facility_id;
                }

                // const user = {
                //   name: this.existingUser.name,
                //   email: this.existingUser.email,
                //   facility: facility,
                //   existsInThisFacility: existsInThisFacility,
                // };

                this.feedback = {
                    name: this.existingUser.name,
                    email: this.existingUser.email,
                    facility: facility,
                    existsInThisFacility: existsInThisFacility,
                };
            } else {
                this.feedback = "Not found.";
            }
        },
        async fetchUsersByFacility() {
            this.tableLoading = true;
            const response = await axios
                .get(`facility-staff/${this.facilityId}`)
                .catch((error) => console.log(error.message));
            this.facilityStaffItems = response.data.data;
            this.totalRows = this.facilityStaffItems.length;
            this.currentPage = 1;
            this.tableLoading = false;
        },
        async fetchRoles() {
            const responsibilities = await axios
                .get("responsibilities")
                .catch((error) => console.log(error.message));

            this.roles = [{value: null, text: "Please select a role."}];

            responsibilities.data.data.forEach((responsibility) => {
                this.roles.push({
                    value: responsibility.id,
                    text: responsibility.designation,
                });
            });
        },
        async saveStaffMember() {
            //  is the post valid?
            //  do we need to create a user? Check for user id.
            //  do we need to add the user to this facility?
            if (this.isReadyToSave) {
                //  what mode are we in? Only two modes: new or add
                if (this.addMode === "existing") {
                    //  we are adding an existing user, we need the user id.
                    const data = {
                        staff_id: this.staff.user.id,
                        facility_id: this.facilityId,
                        employee_number: this.staff.employee_number,
                        started_at: this.staff.started_at,
                        ended_at: this.staff.ended_at,
                        speciality: this.staff.speciality,
                        role_id: this.staff.role_id,
                        work_email: this.staff.work_email,
                        work_number: this.staff.work_number,
                    };

                    this.postData = data;

                    const response = await axios
                        .post("facility-staff", data)
                        .then((response) => {
                            return response.data;
                        })
                        .catch((error) => {
                            if (error.response) {
                                // Request made and server responded
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);

                                return {
                                    success: false,
                                    message: error.response.data.message,
                                    facility_staff: {},
                                };
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                                return {
                                    success: false,
                                    message: error.response.data.message,
                                    facility_staff: {},
                                };
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log("Error", error.message);
                                return {
                                    success: false,
                                    message: error.response.data.message,
                                    facility_staff: {},
                                };
                            }
                        });

                    if (response.success) {
                        await this.$bvModal.msgBoxOk("A new staff member has been added.", {
                            centered: true,
                        });
                        this.resetStaffObject()
                        this.getCareProvidersByFacility(this.facilityId)
                        // refresh table.
                        await this.refreshTable();
                    } else {
                        await this.showErrorModal(response);
                    }
                }

                if (this.addMode === "new") {
                    //  we are adding a new user to this facility.
                    //  we need to add a new user, then add this user to facility-staff.
                    //  create the data object
                    const data = {
                        name: this.staff.user.name,
                        email: this.staff.user.email,
                        password: this.staff.user.password,
                        password_confirmation: this.staff.user.confirm_password,
                        facility_id: this.facilityId,
                        employee_number: this.staff.employee_number,
                        started_at: this.staff.started_at,
                        ended_at: this.staff.ended_at,
                        speciality: this.staff.speciality,
                        role_id: this.staff.role_id,
                        work_email: this.staff.work_email,
                        work_number: this.staff.work_number,
                    };

                    this.postData = data;

                    const response = await axios
                        .post("/facility-staff/add-user", data)
                        .then((response) => {
                            return response.data;
                        })
                        .catch((error) => {
                            if (error.response) {
                                // Request made and server responded
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);

                                return {
                                    success: false,
                                    message: error.response.data.message,
                                    facility_staff: {},
                                };
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                                return {
                                    success: false,
                                    message: error.response.data.message,
                                    facility_staff: {},
                                };
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log("Error", error.message);
                                return {
                                    success: false,
                                    message: error.response.data.message,
                                    facility_staff: {},
                                };
                            }
                        });

                    if (response.success) {
                        await this.$bvModal.msgBoxOk("A new staff member has been added.", {
                            centered: true,
                        });
                        // refresh table.
                        await this.refreshTable();
                    } else {
                        await this.showErrorModal(response);
                    }
                }
            }
        },

        async showErrorModal(response) {
            await this.$bvModal.msgBoxOk(
                "An error occured trying to add the new user: " + response.message,
                {centered: true}
            );
        },

        async refreshTable() {
            await this.fetchUsersByFacility();
            await this.displayStaffMembers();
        },

        async postStaffMember(staffMember) {
            //  add the entry into the facility_staff
            this.saveButtonText = "Adding User to Facility...";
            const facilityStaff = await axios
                .post("facility-staff", staffMember)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => console.log(error.message));
            if (facilityStaff.success) {
                this.saveButtonText = "Saved!";
                this.resetStaffObject();
            }
        },
        addNewStaffMember() {
            this.showAddNewStaffMember = true;
            this.showLookupTool = false;
            this.showExistingStaff = false;
            this.resetStaffObject();
        },
        lookupExistingStaffMember() {
            this.showLookupTool = true;
            this.showAddNewStaffMember = false;
            this.showExistingStaff = false;
        },
        async displayStaffMembers() {
            this.showLookupTool = false;
            this.showAddNewStaffMember = false;
            this.showExistingStaff = true;
            await this.fetchUsersByFacility();
        },
        resetStaffObject() {
            this.staff = {
                user: {
                    name: null,
                    email: null,
                    email_confirm: null,
                    password: null,
                    confirm_password: null,
                },
                facility_id: this.facilityId,
                staff_id: 0,
                employee_number: "",
                started_at: null,
                ended_at: null,
                speciality: "",
                role_id: null,
                work_email: "",
                work_number: "",
            };

            this.saveButtonText = "Save";
            this.saveButtonState = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        isEmailAddress(email) {
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
            );
        },
    },
};
</script>