<template>
    <div>
        <div v-if="isLoading">
            <div class="d-flex justify-content-center mb-3">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>
        <div v-if="!isLoading">
            <b-container fluid class="medium black mt-2">
                <b-row class="justify-content-start" align-v="center" align-h="start">
                    <b-col>
                        <div class="text-danger font-weight-bold">
                            <i class="fas fa-notes-medical"></i> Change in Condition
                        </div>
                        <div class="text-danger font-weight-bold">
                            {{ conditionName }} ({{ isActive }})
                        </div>
                        <div>
                            <span class="small">{{ startedAt | formatDate }}</span>
                        </div>
                    </b-col>
                </b-row>

                <change-in-condition-detail-row :label-text="immediacyText + ` :`" :value-text="changeInConditionSymptom" />
                <change-in-condition-detail-row :label-text="``" :value-text="severityDirection" />
                <change-in-condition-detail-row :label-text="`Last Treatment: `" :content-text="factorsText('last_treatment', 'None')" />
                <change-in-condition-detail-row :label-text="`Negative Factors: `" :content-text="factorsText('negative_factors', 'Not Provided')" />
                <change-in-condition-detail-row :label-text="`Positive Factors: `" :content-text="factorsText('positive_factors', 'Not Provided')" />
                <change-in-condition-detail-row :label-text="`Other Factors: `" :content-text="factorsText('other_factors', 'Not Provided')" />
                <change-in-condition-detail-row :label-text="`Reported By: `" :content-text="reporter" />
                <b-row>
                    <b-col cols="9" class="mt-1">
                        &nbsp;
                    </b-col>
                    <b-col cols="3" class="mt-1">
                        <app-button is-selected is-narrow is-small @click="$emit('toggleShowDetail')">Show Less...
                        </app-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>
import AppButton from "@/components/AppButton.vue";
import {mapGetters} from "vuex";
import moment from "moment";
import ChangeInConditionDetailRow from "@/components/Messaging/ChangeInConditionDetailRow.vue";

export default {
    name: "ChangeInConditionDetail",
    components: {ChangeInConditionDetailRow, AppButton},
    props: {
        assessment: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true
        }
    },
    mounted() {
        this.loadChangeInConditionDetail()
    },
    computed: {
        ...mapGetters({
            changeInCondition: "messages/getChangeInConditionDetail"
        }),
        isActive() {
            return this.changeInCondition?.active === 1 ? 'Active' : 'Resolved'
        },
        immediacyText() {
            if (this.changeInCondition?.is_immediate === 1) {
                return 'Is Immediate'
            }

            return 'Is Not Immediate'
        },
        changeInConditionSymptom() {
            if (this.changeInCondition?.is_immediate === 1) {
                return this.changeInCondition?.change_in_condition?.immediate_symptom
            }

            return this.changeInCondition?.change_in_condition?.non_immediate_symptom
        },
        conditionName() {
            if (this.changeInCondition) {
                return this.changeInCondition?.change_in_condition?.symptom
            }

            return "Loading..."
        },
        startedAt() {
            return this.changeInCondition?.started_at
        },
        severityDirection() {
            if (this.changeInCondition) {
                switch (this.changeInCondition.severity_direction) {
                    case 'same':
                        return "Since starting, this condition has remained the same."
                    case "worse":
                        return "Since starting, this condition has gotten worse."
                    case "better":
                        return "Since starting, this condition has gotten better."
                }
            }

            return "Loading..."
        },
        reporter() {
            if(this.changeInCondition) {
                return this.changeInCondition?.created_by?.name
            }

            return "Not Found"
        },
    },
    methods: {
        async loadChangeInConditionDetail() {
            this.isLoading = true
            await this.$store.dispatch("messages/getChangeInConditionDetail", this.assessment?.assessment_id)
                .finally(async () => {
                    this.isLoading = false
                });
        },
        factorsText(factor, defaultValue = "") {
            console.log("Change in Condition: ", this.changeInCondition)
            if(this.changeInCondition) {
                console.log(`Change in Condition Factor (${factor}): `, this.changeInCondition[factor])
                return this.changeInCondition[factor] ?? defaultValue
            }

            return defaultValue
        },
    },
    filters: {
        formatDate: function (value) {
            if (!value) return "";
            let momentObject = moment(value);

            return momentObject.format('MMMM Do YYYY, h:mm:ss a');
        },
    },
}
</script>
