export function isNumeric(str) {

    if (typeof str != "string") {
        console.log(`This ${str} is not a string.`);
        console.log(`This ${str} is a ` + typeof str);
        return false;
    }
    return !isNaN(str) && !isNaN(parseFloat(str));
}

export function isEmailAddress(data) {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(data);
}

export function isTelephoneNumber(data) {

    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(data)
}

export function generateId() {

    return (new Date).getTime() + Math.random().toString(16).slice(2)
}

export function isNonEmptyString(value) {
    return (typeof value === 'string' && value.length > 0)
}