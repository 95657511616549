<template>
    <div>
        <div v-if="hasExistingSymptoms">
            <div class="residentTable-search-container">
                <button @click="filterTable()">
                    <font-awesome-icon :icon="['fas', 'search']"/>
                </button>
                <input
                    v-model="searchString"
                    type="text"
                    placeholder="Search"
                    class="residentTable-search-bar"
                    v-on:keyup="filterTable()"
                />
                <button style="float: right" @click="clearFilter()">
                    <font-awesome-icon :icon="['fas', 'times']"/>
                </button>
            </div>
            <b-table
                striped
                id="signsSymptomsTable"
                :items="episodeSignsAndSymptoms"
                class="residentTable-style"
                :fields="fields"
                thead-class="table-headers"
                tbody-class="table-body"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template
                    v-for="field in editableFields"
                    v-slot:[`cell(${field.key})`]="{ value, item }"
                >
                    <b-form-textarea
                        v-if="selectedRow && selectedRow.id === item.id && isEditShow"
                        :key="field.id"
                        v-model="selectedRow[field.key]"
                        :type="field.type || 'text'"
                        :number="field.isNumber"
                        rows="4"
                        max-rows="4"
                    ></b-form-textarea>
                    <template v-else>{{ value }}</template>
                </template>
                <template v-slot:cell(actions)="{ item }">
                    <b-button-group
                        v-if="selectedRow && selectedRow.id === item.id && isEditShow"
                    >
                        <b-btn class="residentTable-save-button" @click="saveEdit">
                            SAVE
                        </b-btn>
                    </b-button-group>
                    <b-btn
                        :disabled="meNotAuthorised"
                        v-else
                        class="residentTable-edit-button"
                        @click="edit(item)"
                    >
                        EDIT
                    </b-btn>
                </template>
<!--                <template v-slot:cell(remove)="{ item }">-->
<!--                    <b-link :disabled="meNotAuthorised" @click="showRemoveModal(item)">-->
<!--                        <font-awesome-icon-->
<!--                            class="residentTable-remove-link"-->
<!--                            :icon="['fas', 'times']"-->
<!--                        />-->
<!--                    </b-link>-->
<!--                </template>-->
                <template v-slot:cell(feature)="{ item }">
                    <change-in-condition-action-button :change-in-condition="item"></change-in-condition-action-button>
                </template>
            </b-table>
        </div>
        <div v-else>
            <div class="text-center"><h1>There are no Changes in Conditions for this Patient</h1></div>
        </div>

        <b-modal id="confirmRemoveModal" centered hide-footer hide-header>
            <div class="d-block text-center">
                <h4 class="primaryTypeFace">
                    Are you sure you want to resolve this Change in Condition?
                </h4>
            </div>
            <div class="d-block text-center">
                <b-button
                    class="mt-3 residentTable-model-delete-btn"
                    @click="deletePatientSymptom()"
                >RESOLVE
                </b-button
                >
                <b-button
                    class="mt-3 residentTable-model-cancel-btn"
                    block
                    @click="hideModal"
                >CANCEL
                </b-button
                >
            </div>
        </b-modal>
    </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import ChangeInConditionActionButton from "./ChangeInCondition/ChangeInConditionActionButton.vue";
import { EventBus} from "../../event-bus";

export default {
    components: {ChangeInConditionActionButton},

    props: {
        action: Function,
    },
    created() {
        EventBus.$on('resolveChangeInCondition', this.showRemoveModal)
    },

    data() {
        return {
            searchString: "",
            fields: [
                {
                    key: "id",
                    thClass: "d-none",
                    tdClass: "d-none"},
                {
                    key: "symptom",
                    label: "Condition",
                    editable: true,
                    sortDirection: "desc",
                    sortable: true,
                    thStyle: "width:15%;",
                },
                {
                    key: "startDate",
                    label: "Start Date",
                    editable: false,
                    sortable: true,
                    thStyle: "width:15%;",
                },
                {
                    key: "situation",
                    label: "Situation",
                    editable: true,
                    thStyle: "width:20%;",
                },
                {
                    key: "condition",
                    label: "Condition",
                    editable: true,
                    thStyle: "width:20%;",
                },
                {
                    key: "actions",
                    label: "Details",
                    thClass: "width:20%;"
                },
                {
                    key: "feature",
                    label: "Actions",
                    thClass: "width:20%;",
                    tdClass: "width:20%;",
                },

            ],
            sortBy: "startDate",
            sortDesc: true,
            selectedRow: null,
            meNotAuthorised: false,
        };
    },

    computed: {
        ...mapGetters({
            signsAndSymptoms: "configuration/signsAndSymptoms",
            activeSignsAndSymptoms: 'episode/getSignsAndSymptoms',
            getSignsAndSymptom: 'episode/getSignsAndSymptom',
            getCurrentSignsAndSymptom: 'episode/getCurrentSignsAndSymptom',
            episode: "episode/getEpisode",
            user: "authentication/user",
        }),
        hasExistingSymptoms() {

            return this.episode?.signsAndSymptoms?.filter(condition => condition.action !== "D").length > 0
        },
        editableFields: function () {

            return this.fields.filter((field) => field.editable);

        },
        episodeSignsAndSymptoms() {

            if (this.hasExistingSymptoms) {

                const assessments = this.activeSignsAndSymptoms.map((episodeSignsAndSymptom) => {

                    if(episodeSignsAndSymptom.action === 'D') {

                        return
                    }

                    let sas = this.signsAndSymptoms.find((signsAndSymptom) => signsAndSymptom.id === episodeSignsAndSymptom.signs_and_symptom_id)

                    if(!sas) {
                        return
                    }

                    let occurrence = episodeSignsAndSymptom.is_reoccurrence
                        ? "This condition, symptom or sign has occurred before."
                        : "This condition, symptom or sign has not occurred before."

                    let situation = episodeSignsAndSymptom.immediacy === 'true'
                        ? sas.immediate_symptom
                        : sas.non_immediate_symptom

                    return {
                        id: episodeSignsAndSymptom.id,
                        symptom: sas.symptom,
                        startDate: episodeSignsAndSymptom.started_at,
                        situation: situation,
                        condition: occurrence + " " + episodeSignsAndSymptom.other_factors,
                        intervention: sas.intervention ? sas.intervention : 'none',
                        signs_and_symptom_id: episodeSignsAndSymptom.signs_and_symptom_id,
                        action: episodeSignsAndSymptom.action,
                    }

                })

                if (assessments?.length > 0) {

                    return assessments
                }

                return []
            }

            return []
        },
    },

    methods: {
        ...mapActions({
            setSymptom: 'episode/setSignAndSymptom',
            setAssessment: 'episode/setAssessment',
            deleteSignAndSymptom: 'episode/deleteSignsAndSymptom',
            setNewSignAndSymptom: 'episode/setNewSignAndSymptom',
        }),
        filterTable() {
            var loFilter, loTable, loTr, loTd;
            loFilter = this.searchString.toUpperCase();
            loTable = document.getElementById("signsSymptomsTable");
            loTr = loTable.getElementsByTagName("tr");
            for (var i = 1; i < loTr.length; i++) {
                loTd = loTr[i].getElementsByTagName("td");
                var loShowRow = false;
                for (var x = 1; x < loTd.length; x++) {
                    if (loTd[x]) {
                        var loTxtValue = loTd[x].textContent || loTd[x].innerText;
                        if (loTxtValue.toUpperCase().indexOf(loFilter) > -1) {
                            loShowRow = true;
                            break;
                        }
                    }
                }
                if (loShowRow === true) {
                    loTr[i].style.display = "";
                } else {
                    loTr[i].style.display = "none";
                }
            }
        },

        clearFilter() {
            this.searchString = "";
            var loTable, loTr;

            loTable = document.getElementById("signsSymptomsTable");
            loTr = loTable.getElementsByTagName("tr");
            for (var i = 1; i < loTr.length; i++) {
                loTr[i].style.display = "";
            }
        },

        edit(signAndSymptom) {

            //  set the current sign and symptom to the sign and symptom clicked on
            //  set the Assessment
            //  get the current Signs and Symptoms
            const currentAssessments = this.activeSignsAndSymptoms
            if(currentAssessments.length === 0) {
                //  an error has occurred, how did they click a button for something that does not exist.
                console.error("Invalid selection criteria on Signs and Symptoms for an Assessment.")
                return
            }

            //  find the specific one they are editing.
            const assessment = currentAssessments.find(assessment => assessment.signs_and_symptom_id === signAndSymptom.signs_and_symptom_id)

            if(assessment === undefined) {
                //  an error occurred, the assessment was not found.
                console.error(`The assessment with Signs And Symptom ID ${signAndSymptom.signs_and_symptom_id} was not found.`)
                return
            }

            //  set the assessment
            this.setAssessment(assessment)

            //  set the Sign and Symptom
            this.setNewSignAndSymptom(signAndSymptom.signs_and_symptom_id)

            //  display the situation view.
            this.action()
        },

        showRemoveModal(paSelectedselectedRow) {
            this.isEditShow = false;
            this.selectedRow = paSelectedselectedRow;
            this.$bvModal.show("confirmRemoveModal");
        },

        hideModal() {
            this.selectedRow = null;
            this.$bvModal.hide("confirmRemoveModal");
        },

        deletePatientSymptom() {
            this.deleteSignAndSymptom(this.selectedRow.signs_and_symptom_id)
            this.hideModal()
        },

        addToTableState(paNumberState) {
            if ((this, this.isInAddMode === false)) {
                this.isInAddMode = true;
                this.editedPatientSymptom = '';
                this.patient_symptoms = {
                    episode_id: '',
                    symptom_token: '',
                    type: '',
                    started: '',
                    occurrence: '',
                    severity: '',
                    last_treatment: '',
                    negative_factors: '',
                    positive_factors: '',
                    other: '',
                    intervention: '',
                }
                this.activeTab = paNumberState;
            }
        },

        proceedToSituation() {

            this.meSignSymptomStep = 2;
            const symptoms = this.signsAndSymptoms
            const selectedSymptom = symptoms.find((symptom) => symptom.symptom_token === this.selectSignAndSymptom)
            this.setSymptom(selectedSymptom);
        },
    },
};
</script>
