<template>
    <div>
        <span @click="notify" class="mouse-pointer"><notify-icon
            :changeInCondition="changeInCondition" v-b-popover.hover.top="`Notify`"></notify-icon></span>
        <span @click="escalate" class="mouse-pointer"><escalate-icon
            :changeInCondition="changeInCondition" v-b-popover.hover.top="`Escalate`"></escalate-icon></span>
        <span @click="resolve" class="mouse-pointer"><resolve-icon
            :changeInCondition="changeInCondition"  v-b-popover.hover.top="`Resolve`"></resolve-icon></span>
        <b-modal
            ref="modal-unsaved-changes-cic"
            title="Unsaved Changes"
            centered
            header-bg-variant="dark"
            header-text-variant="danger"
            hide-footer
            hide-header-close
        >
            <p class="my-4">
                You have unsaved changes. <br/>
                You must save your changes before you can communicate them to the
                primary care provider <br/>
                Would you like to save your changes?
            </p>
            <div class="text-center">
                <app-button @click="saveResidentData()" :is-disabled="isSaving">{{ saveButtonText }}</app-button>
                <app-button
                    @click="cancelMessageBoxShow('modal-unsaved-changes')"
                    isDanger
                >CLOSE
                </app-button
                >
            </div>
        </b-modal>

        <b-modal
            ref="modal-error-saving-cic"
            title="Error Saving Changes"
            centered
            header-bg-variant="dark"
            header-text-variant="danger"
            hide-footer
            hide-header-close
        >
            <p class="my-4">
                <strong>We were unable to save the changes.</strong>
            </p>

            <h3>
                {{ episodeErrorsText }}
            </h3>
            <div v-for="error in episodeErrors.errors" :key="(index, error)">
                {{ error }}
            </div>

            <div class="text-center">
                <app-button @click="cancelMessageBoxShow('modal-error-saving')" isDanger
                >CLOSE
                </app-button
                >
            </div>
        </b-modal>
    </div>
</template>

<script>

import NotifyIcon from "./NotifyIcon.vue";
import EscalateIcon from "./EscalateIcon.vue";
import ResolveIcon from "./ResolveIcon.vue";
import {EventBus} from "../../../event-bus.js"
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppButton from "../../AppButton.vue";

export default {
    name: "ChangeInConditionActionButton",
    components: {AppButton, ResolveIcon, EscalateIcon, NotifyIcon},
    props: {
        changeInCondition: Object
    },
    data() {
        return {
            isSaving: false,
        }
    },
    methods: {
        ...mapMutations({
            unloadMessages: "messages/unloadMessages",
            setMessageEpisodeId: "messages/setMessageEpisodeId"
        }),
        ...mapActions({
            openMessages: "messages/openMessages",
            loadMessages: "messages/loadMessages",
            saveEpisode: "episode/saveEpisode",
        }),
        escalate() {
            EventBus.$emit("shiftToPage", 'escalations')
        },
        async notify() {
            if (this.isDirty) {
                await this.showConfirmUnsavedChangesModal();
                return;
            }

            this.openMessages()
        },
        showConfirmUnsavedChangesModal() {
            this.$refs["modal-unsaved-changes-cic"].show();
        },
        resolve() {
            EventBus.$emit("resolveChangeInCondition", this.changeInCondition)
        },
        async saveResidentData() {
            this.isSaving = true
            await this.saveEpisode().finally(() => {
                this.isSaving = false
            });

            if (this.episodeHasErrors === true) {
                this.$refs["modal-error-saving-cic"].show();
            } else {
                this.cancelMessageBoxShow("modal-error-saving-cic");
                this.cancelMessageBoxShow("modal-unsaved-changes-cic");
                await this.loadMessageWindowAfterSave();
            }
        },
        cancelMessageBoxShow(modal) {
            if (this.$refs[modal]) {
                this.$refs[modal].hide();
            }
        },
        async loadMessageWindowAfterSave() {

            await this.setMessageEpisodeId(this.getEpisodeId);
            await this.openMessages();
            await this.loadMessages();
        },
    },
    computed: {
        ...mapGetters({
            episode: 'episode/getEpisode',
            isDirty: 'episode/isDirty',
            interventions: 'configuration/interventions',
            getIntervention: "episode/getIntervention",
            currentIntervention: "episode/getCurrentIntervention",
            getEpisodeId: "episode/getEpisodeId",
            episodeErrors: "episode/episodeErrors",
            episodeHasErrors: "episode/episodeHasErrors",
        }),
        episodeErrorsText() {
            if (this.episodeHasErrors) {
                return this.episodeErrors.error;
            }

            return "";
        },
        saveButtonText() {
            if (this.isSaving) {
                return "Saving..."
            }

            return "Save"
        },
    },
}
</script>

<style scoped>
.mouse-pointer {
    cursor: pointer;
}
</style>