<template>
    <b-icon icon="messenger" :class="isUrgentClass" :animation="isUrgentAnimation" variant="light" font-scale="2"></b-icon>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "NotifyIcon",
    props: {
        changeInCondition: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            activeSignsAndSymptoms: 'episode/getSignsAndSymptoms',
        }),
        isUrgentClass() {
            return {
                'bg-danger': this.isUrgent,
                'bg-primary': !this.isUrgent,
                'rounded-circle': true,
                'p-2': true,
                'm-2': true
            }
        },
        isUrgentAnimation() {
            return this.isUrgent ? 'fade' : null;
        },
        isUrgent() {
            return this.requiresNotification && !this.hasNotified;
        },
        currentChangeInCondition() {
            return this.activeSignsAndSymptoms.find(sign => sign.signs_and_symptom_id === this.changeInCondition.signs_and_symptom_id)
        },
        requiresNotification() {
            return this.currentChangeInCondition.requires_notification === 1 || this.currentChangeInCondition.requires_notification === true;
        },
        isImmediate() {
            return this.currentChangeInCondition.is_immediate === 1 || this.currentChangeInCondition.is_immediate === true;
        },
        hasNotified() {
            return this.currentChangeInCondition.has_notified === 1 || this.currentChangeInCondition.has_notified === true;
        }
    }
}
</script>

<style scoped>

</style>