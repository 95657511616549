<template>
    <div
        class="msgs-notifications profile-icon dropdown"
        :[user_menu_attribute]="user_menu_attribute_val"
    >
        <a class="dropdown-toggle">
            <ProfileAvatar :size="'2.25rem'" />
        </a>

        <ul class="dropdown-menu">
            <div
                class="row"
                style="
              background-color: #88abca63;
              padding-bottom: 8px;
              text-align: center;
            "
            >
                <div class="col">
                    <div class="row mb-3">
                        <div class="col">
                            <ProfileAvatar :size="'4rem'" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <b style="padding-top: 30px" id="staffName2">
                                {{ username }}
                                <br/>
                                <span>{{ role }}</span>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <li class="user-menu-btn" v-if="isSiteAdministrator">
                <button @click="manageFacilities()" class="user-menu-item">
                    <i class="fas fa-hospital fa-2x"></i>
                    <span>Manage Facilities</span>
                </button>
            </li>

            <li class="user-menu-btn" v-if="isSiteAdministrator">
                <button @click="onboardPCCFacilities()" class="user-menu-item">
                    <i class="far fa-hospital fa-2x"></i>
                    <span>Add PCC Facility</span>
                </button>
            </li>

            <li class="user-menu-btn" v-if="isAdministrator">
                <button @click="managerStaff()" class="user-menu-item">
                    <i class="fa fa-user-md fa-2x"></i>
                    <span>Manage Staff</span>
                </button>
            </li>

            <li class="user-menu-btn">
                <button @click="changePassword()" class="user-menu-item">
                    <i class="fa fa-unlock-alt fa-2x"></i>
                    <span>Change Password</span>
                </button>
            </li>

            <li class="user-menu-btn">
                <button @click="signOut()" class="user-menu-item">
                    <i class="fa fa-sign-out fa-2x"></i>
                    <span>Sign Out</span>
                </button>
            </li>
        </ul>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import ProfileAvatar from "@/components/ProfileAvatar.vue";


export default {
    name: "UserMenu",
    components: {ProfileAvatar},
    data: function () {
        return {
            user_menu_attribute: "data-toggle",
            user_menu_attribute_val: "dropdown",
        }
    },
    computed: {
        ...mapGetters({
            user: "authentication/user",
            role: "user/getRole",
            isSiteAdministrator: "user/isSiteAdministrator",
            isAdministrator: "user/isAdministrator",
        }),
        username() {
            return this.user ? this.user?.name : "";
        },
    },
    methods: {
        manageFacilities: function () {
            this.$bvModal.show("facilityManager");
            // this.$router.push({ name: 'facilityManager' })
        },
        onboardPCCFacilities() {
            this.$bvModal.show("pointClickCareOnboarding");
        },
        removePCCFacilities() {
            this.$bvModal.show("pointClickCareDischarging");
        },
        managerStaff: function () {
            this.$bvModal.show("facilityStaffManager");
        },
        changePassword() {
            this.$bvModal.show("newPasswordModal");
        },
        async signOut() {
            this.$emit("signOut");
        },
    },
    }
</script>
<style scoped>

</style>