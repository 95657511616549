<template>
    <b-icon icon="check-lg" class="rounded-circle bg-primary p-2 m-2" variant="light" font-scale="2"></b-icon>
</template>

<script>
export default {
    name: "ResolveIcon"
}
</script>

<style scoped>

</style>