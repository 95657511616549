<template>
    <b-container :id="'episode_' + episode.id" fluid>
        <b-row>
            <b-col>
                <div>{{ episodeStatus }}</div>
                <div
                    :class="{
                    'text-danger': isHighPriority,
                        'text-success': ! isHighPriority,
                    }"
                >{{ episodeMessage }}
                </div>
            </b-col>
        </b-row>
        <b-popover :target="'episode_' + episode.id" triggers="hover" placement="right">
            <template #title><span :class="{'text-danger': isHighPriority,
                        'text-success': ! isHighPriority,}">{{ episodeMessage }}</span></template>
            <div v-if="hasActiveChangesInCondition">
                {{ episode.patientFullName }} has {{ episode.signsAndSymptoms.length }} active Change in Condition(s).
                <b-list-group flush>
                    <b-list-group-item v-for="changeInCondition in episode.signsAndSymptoms" :key="changeInCondition.symptom">
                        {{ changeInCondition.symptom }}
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div v-if="hasActiveEscalations">
                {{ episode.patientFullName }} has {{ episode.interventions.length }} active Escalation(s).
                <b-list-group flush>
                    <b-list-group-item v-for="escalation in episode.interventions" :key="escalation.id">
                        {{ escalation.intervention.intervention }}
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div v-if="hasVitalSignsThatNeedNotification">
                {{ episode.patientFullName }} has {{ episode.vitalSigns.length }} unnotified Vital Sign(s).
                <b-list-group flush>
                    <b-list-group-item v-for="vitalSign in episode.vitalSigns" :key="vitalSign">
                        <b-list-group flush>
                            <b-list-group-item v-for="sign in vitalSign" :key="sign">{{ sign }}</b-list-group-item>
                        </b-list-group>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-popover>
    </b-container>
</template>
<script>
import index, {mapActions, mapGetters} from "vuex";
import SignsAndSymptoms from "@/store/episode/signsAndSymptoms";

export default {

    props: {
        episode: Object,
    },

    computed: {
        index() {
            return index
        },
        SignsAndSymptoms() {
            return SignsAndSymptoms
        },
        ...mapGetters({
            interventions: "configuration/interventions",
        }),
        hasActiveEscalations() {
            return this.episode.interventions.length > 0
        },
        hasActiveChangesInCondition() {
            return this.episode.signsAndSymptoms.length > 0
        },
        episodeMessage: function () {
            /***
             * We must check the Interventions, Signs and Symptoms, and Vital Signs.
             *
             * If we have one or more interventions that are in Communicate, Monitor, or Priority,
             * we must state whether the doctor has been notified or not. If any of the interventions
             * have not been passed to the doctor, we must alert that they require notification.
             */
            let message = "No Message."
            let vitalSignMessage = ""
            let notificationMessage = ""

            if (this.needsVitalSigns) {

                vitalSignMessage = "There are no vital signs for this resident."
            }

            if (this.needsNotification) {

                notificationMessage = "Notify Doctor Immediately."
            }

            if (this.hasNotifiables && !this.needsNotification) {

                notificationMessage = "The Doctor has been notified."
            }

            return this.needsVitalSigns ? vitalSignMessage + ', ' + notificationMessage : notificationMessage.length > 0 ? notificationMessage : message
        },
        hasVitalSignsThatNeedNotification() {
            return this.episode?.vitalSigns?.length > 0
        },

        episodeStatus() {
            return (
                this.signAndSymptomsMessage +
                " " +
                this.interventionMessage + " " + this.vitalSignsMessage
            );
        },
        vitalSignsMessage() {
            if (!this.hasVitalSignsThatNeedNotification) {
                return ""
            }

            const vitalSigns = Array.isArray(this.episode.vitalSigns) ? this.episode.vitalSigns : []

            if (vitalSigns.length === 0) {
                return ""
            }

            if (vitalSigns.length > 1) {
                return "Has multiple Vitals that require notification."
            }

            const [singleVitalSignGroup] = vitalSigns

            const message = singleVitalSignGroup.join(", ")

            if (message.length > 100) {
                return "Has multiple Vitals that require notification."
            }

            return message
        },
        signAndSymptomsMessage() {

            if (this.episode.signsAndSymptoms?.length > 1) {

                return this.episode.patientFullName + ' has multiple active Changes in Condition'
            }

            if (this.episode.signsAndSymptoms?.length === 1) {

                return this.episode.patientFullName + ' has a(n) ' + this.episode.signsAndSymptoms[0].symptom + ' active'
            }

            return this.episode.patientFullName + " has no active Change in Conditions";
        },

        interventionMessage() {

            let message;

            if (this.episode.interventions.length > 0) {

                const urgentInterventions = this.episode.interventions.filter(
                    (intervention) => {
                        return intervention.status === "Priority"
                    }
                )

                if (urgentInterventions.length > 0) {

                    this.setEpisodeToHighPriority(this.episode)
                }
            }

            switch (this.episode.interventions.length) {

                case 0:
                    message = " and No Active Escalations."
                    break;
                case 1:
                    message = `with ${this.episode.interventions[0].intervention.intervention}.`;
                    break;
                default:
                    message = " with Multiple Escalations."
            }

            return message;
        },
        isHighPriority() {

            //  if this episode does not have any vital signs, or has any coc or escalations that are unnotified.
            return this.needsVitalSigns || this.needsNotification
        },

        needsVitalSigns() {

            return this.episode.needsVitalSigns
        },
        needsNotification() {

            return this.hasUnnotifiedChangesInCondition || this.hasUnnotifiedEscalations || this.hasVitalSignsThatNeedNotification
        },

        hasUnnotifiedChangesInCondition() {
            return this.episode.signsAndSymptoms?.filter(this.requiresNotification).length > 0
        },

        hasUnnotifiedEscalations() {

            return this.episode.interventions?.filter(this.requiresNotification).length > 0
        },

        hasNotifiables() {
            return this.episode.interventions.length > 0 || this.episode.signsAndSymptoms.length > 0 || this.episode.vitalSigns.length > 0
        },
    },

    methods: {
        ...mapActions({
            setEpisodeToHighPriority: "episodes/setEpisodePriorityToHigh",
        }),

        requiresNotification(item) {
            return item?.notified === 0;
        },


    }
}
</script>