/**
 * VITAL SIGNS
 * 
 * The following values and functions are based on the specification of the Signs and Symptoms documentation
 * as presented in the INTERACT document.
 * 
 * Blood Glucose: any value above 300 or below 70.
 * Blood Pressure: any value above 200 or less than 90 systolic.
 * Blood Pressure: any value above 115 diastolic
 * Pulse: more than 100, or less than 50
 * Respiratory Rate: more than 28 or less than 10 per minute
 * Temperature: an oral temperature of more than 100.5 F
 * Oxygen Saturation: any value less than 90 %
 */

export const notificationCriteria = (vital) => {
    
    return (vital.notified === 0 || vital.notified === null || vital.notified === false) && vitalSignRequiresNotification(vital)
}

export const vitalSignRequiresNotification = (vitals) => {

  const bloodGlucose = vitals.blood_glucose;
  const bloodPressure = vitals.blood_pressure;
  const pulse = vitals.heart_rate;
  const restingPulse = vitals.resting_pulse;
  const respiratoryRate = vitals.respiration_rate;
  const temperature = vitals.temperature;
  const oxygenSaturation = vitals.o2_saturation;
  const irregularPulse = vitals.irregular_pulse;

  return bloodGlucoseVerification(bloodGlucose) ||
      bloodPressureVerification(bloodPressure) ||
      pulseVerification(pulse) ||
      respiratoryRateVerification(respiratoryRate) ||
      temperatureVerification(temperature) ||
      oxygenSaturationVerification(oxygenSaturation) ||
      pulseVerification(restingPulse) ||
      hasIrregularPulse(irregularPulse);
}

export const hasIrregularPulse = (irregularPulse) => {

  return irregularPulse === "1" || irregularPulse === 1 || irregularPulse === true;
}

export const bloodGlucoseVerification = (bloodGlucose) => {

    return (
        bloodGlucose !== null &&
        bloodGlucose !== "" &&
        (bloodGlucose >= 300 || bloodGlucose <= 70)
      )  
}

export const bloodPressureVerification = (bloodPressure) => {
  if (bloodPressure != null) {
    const bloodPressures = bloodPressure.split("/");
    const systolic = bloodPressures[0];
    const diastolic = bloodPressures[1];

    return (bloodPressure !== "" && (systolic >= 200 || systolic <= 90 || diastolic >= 115));
  }

    return false
}

export const pulseVerification = (pulse) => {
  
    return pulse != null && pulse !== "" && (pulse >= 100 || pulse <= 50);
}

export const respiratoryRateVerification = (respiratoryRate) => {
  return (
    respiratoryRate !== null &&
    respiratoryRate !== "" &&
    (respiratoryRate >= 28 || respiratoryRate <= 10)
  );
}

export const temperatureVerification = (temperature) => {
  return temperature !== null && temperature !== "" && temperature >= 100.5;
}

export const oxygenSaturationVerification = (oxygenSaturation) => {
  return (
    oxygenSaturation !== null && oxygenSaturation !== "" && oxygenSaturation <= 90
  );
}
